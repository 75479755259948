import React, { useState } from 'react';
export default function App(props) {
  const [activeItem, setActiveItem] = useState([0]);
  const [activeItemNull, setActiveItemNull] = useState(props.list.length === 1 ? 0 : null);
  useState(() => {
    props.list.length < 3 &&
      props.list?.map((list, index) => {
        index < 2 && setActiveItem((activeItem) => [...activeItem, list?.id]);
      });
  }, props.list);
  return props.list?.map((list, index) => {
    return (
      <div className="current_item" key={'order_' + index}>
        <div
          className={'current_item-toggle' + (activeItem.includes(list?.id) ? ' active' : '')}
          onClick={() => (
            activeItem.includes(list.id)
              ? setActiveItem(activeItem.filter((obj) => obj != list.id))
              : setActiveItem([...activeItem, list?.id]),
            index === activeItemNull &&
              (setActiveItemNull(null), setActiveItem(activeItem.filter((obj) => obj != list.id)))
          )}></div>
        <div
          className="current_item-header"
          onClick={() => (
            activeItem.includes(list.id)
              ? setActiveItem(activeItem.filter((obj) => obj != list.id))
              : setActiveItem([...activeItem, list?.id]),
            index === activeItemNull &&
              (setActiveItemNull(null), setActiveItem(activeItem.filter((obj) => obj != list.id)))
          )}>
          <div className="current_item-header-left">
            <div className="current_item-header-left-box">
              <div className="current_item-header-number">Заказ №{list.account_number}</div>
              <div className="current_item-header-date">
                {list.date[0]}
                <span className="current_item-header-date-time">{list.date[1].slice(0, 5)}</span>
              </div>
            </div>
            <div
              className={
                'current_item-header-state ' +
                (list.status && list.status_id === 'ON' && ' cancel') +
                (list.status && list.status_id === 'F' && ' ok')
              }>
              {list.status}
            </div>
          </div>
          <div className="current_item-header-right">
            <div className="current_item-header-summ">
              Итого: <span>{list.price}</span>
            </div>
          </div>
        </div>
        <div className={'current_item-box' + (activeItem.includes(list?.id) ? ' active' : '')}>
          <div className="current_item-body">
            {list.basket_items?.map((listItem, index) => {
              return (
                <div className="current_item-body-item" key={'current_item-body-item-' + index}>
                  <div className="current_item-body-item-left">
                    <div className="current_item-body-item-name">{listItem.name}</div>
                    <div className="current_item-body-item-article">Артикул: {listItem.sku}</div>
                    <div className="current_item-body-item-coll">
                      {listItem.count} шт х {listItem.price}
                    </div>
                  </div>
                  <div className="current_item-body-item-right">
                    <div className="current_item-body-item-sum">{listItem.sum}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={'current_item-footer' + (activeItem.includes(list?.id) ? ' active' : '')}>
          <div className="current_item-footer-left"></div>
          <div className="current_item-footer-right">
            <a
              href={props.API_URL + '/order/invoice?id=' + list.id + '&download=true'}
              className="current_item-footer-check">
              Товарный чек
            </a>
            <div className="current_item-footer-summ">
              <span>Итого:</span>
              {list.price}
            </div>
          </div>
        </div>
      </div>
    );
  });
}
