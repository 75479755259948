import React, { useState, useEffect } from 'react';
import SideNav from './SideNav';
import Input from './InputBlock';
import InputS from './Input';
import OrderList from './OrderList';
import Paginate from './Paginate';

export default function App(props) {
  useEffect(() => {
    !localStorage.getItem('token') && window.location.replace('/login');
    document.body.classList.add('personal');
    props.setErrTextApi('');
    props.setRoomes([]);
    props.setBlockN([]);
    personal();
  }, []);
  const [dataF, setDataF] = useState([]);

  const personal = () => {
    var myHeaders = new Headers();
    myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
    fetch(props.API_URL + '/profile/fields', {
      method: 'GET',
      headers: myHeaders,
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem('token');
        window.location.replace('/login');
      } else {
        response.json().then(function (data) {
          if (data && data.status === true) {
            let initMass = [];
            props.setRoomesN(...initMass);
            setDataF(data.data.fields);
            setDataF((roomsN) =>
              Object.entries(roomsN).map(
                ([key, value]) => (
                  (value['err'] = false),
                  // (value['value'] = key === 'name_type' ? 'Господин' : ''),
                  (value['value'] =
                    key === 'title'
                      ? value.fields[0].selected
                        ? value.fields[0].value
                        : value.fields[1].selected
                        ? value.fields[1].value
                        : value.fields[0].value
                      : ''),
                  (value['errorText'] = '')
                ),
              ),
            );
            props.setRoomesN(new Object(data.data.fields));
            props.setBlockN([...data.data.blocks]);
          }
        });
      }
    });
  };

  return (
    <div className="content d-flex m-f-column">
      <SideNav
        menu={props.menu}
        profile={props.profile}
        submit={props.submit}
        setStatement={props.setStatement}
      />
      <div className="content__right pos-relative">
        {props.errTextApi !== '' && (
          <div
            className="form__item form__err"
            dangerouslySetInnerHTML={{ __html: props.errTextApi }}></div>
        )}
        <div className="content__page">
          <div className="form text-left d-flex d-aling-left d-f-column">
            <br />
            <br />
            <br />
            <Input
              blockN={props.blockN}
              rooms={props.roomesN}
              setRooms={props.setRoomesN}
              setValidation={props.setValidationB}
              togglePass={props.togglePass}
              handleroom={props.handleroom}
            />
            <button
              onClick={() => {
                props.submit('editProfile');
              }}
              className={'btn' + (!props.subActive ? ' btn-grey' : ' btn-blue2')}>
              Сохранить изменения
            </button>
            <br />
            <br className="m-hidden" />
            <br className="m-hidden" />
            <InputS
              rooms={props.roomes}
              setRooms={props.setRoomes}
              setValidation={props.setValidation}
              togglePass={props.togglePassS}
              handleroom={props.handleroomS}
            />
            {props.roomes.length > 0 ? (
              <button
                onClick={() => {
                  props.submit('savePassword');
                }}
                className={'btn' + (!props.subActive2 ? ' btn-grey' : ' btn-blue2')}>
                Сохранить пароль
              </button>
            ) : (
              <button
                onClick={() => {
                  props.setRoomes([...props.formInput2['editPass']]);
                }}
                className={'btn btn-blue2'}>
                Изменить пароль
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
