import React from 'react';
import ReactPaginate from 'react-paginate';
export default function App(props) {
  return (
    <ReactPaginate
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={props.listPagin?.count / props.listPagin?.per_page}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      initialPage={props.listPagin?.page - 1}
      onPageChange={props.handlePageClick}
      containerClassName={'pagination'}
      subContainerClassName={'pages pagination'}
      previousLabel={'Предыдущая'}
      nextLabel={'Следующая'}
      activeClassName={'active'}
    />
  );
}
