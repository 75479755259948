// import React from 'react';
// import { render } from 'react-dom';
// import { BrowserRouter } from 'react-router-dom';

// import App from './App';

// render(
//   <BrowserRouter basename="/">
//     <App />
//   </BrowserRouter>,
//   document.getElementById('root'),
// );

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// настройки плагина
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
