import React, { useState, useEffect } from 'react';
import SideNav from './SideNav';
import Modal from './Modal';
import Input from './InputBlockMobile';

export default function App(props) {
  useEffect(() => {
    !localStorage.getItem('token') && window.location.replace('/login');
    document.body.classList.add('address');
    props.setErrTextApi('');
    props.setBlockN([]);
    props.submit('addressList');
  }, []);
  const [list, setList] = useState([]);
  const [dataF, setDataF] = useState([]);
  const [listOrderHist, setListOrderHist] = useState([]);
  const List = () => {
    var myHeaders = new Headers();
    myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
    fetch(props.API_URL + '/address/list', {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem('token');
        window.location.replace('/login');
      } else {
        response.json().then(function (data) {
          if (data.status === true) {
            setList([...data.data, ...data.data]);
          } else {
            console.log(data);
          }
        });
      }
    });
  };
  const form = () => {
    props.setErrTextApi('');
    var myHeaders = new Headers();
    myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
    let url = '';
    localStorage.getItem('editAddressId')
      ? (url = props.API_URL + '/address/fields-cols?id=' + localStorage.getItem('editAddressId'))
      : (url = props.API_URL + '/address/fields-cols');
    fetch(url, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem('token');
        window.location.replace('/login');
      } else {
        response.json().then(function (data) {
          if (data && data.status === true) {
            let initMass = [];
            props.setRoomesN(...initMass);
            setDataF(data.data.fields);
            setDataF((roomsN) =>
              Object.entries(roomsN).map(
                ([key, value]) => (
                  (value['err'] = false),
                  // (value['value'] = key === 'name_type' ? 'Господин' : ''),
                  (value['value'] =
                    key === 'name_type'
                      ? value.fields[0].selected
                        ? value.fields[0].value
                        : value.fields[1].selected
                        ? value.fields[1].value
                        : value.fields[0].value
                      : ''),
                  (value['errorText'] = '')
                ),
              ),
            );
            props.setRoomesN(new Object(data.data.fields));
            // props.roomesN !== [] && console.log(props.roomesN);
            props.setBlockN([...data.data.blocks[0].block.cols]);
            setTimeout(() => {
              props.runModalShow(0, 'newAddress');
            }, 300);
          }
        });
      }
    });

    // })
    //   .then((response) => response.json())
    //   .then((response) => {
    //     if (response && response.status === true) {
    //       let initMass = [];
    //       props.setRoomesN(...initMass);
    //       setDataF(response.data.fields);
    //       setDataF((roomsN) =>
    //         Object.entries(roomsN).map(
    //           ([key, value]) => (
    //             (value['err'] = false),
    //             // (value['value'] = key === 'name_type' ? 'Господин' : ''),
    //             (value['value'] =
    //               key === 'name_type'
    //                 ? value.fields[0].selected
    //                   ? value.fields[0].value
    //                   : value.fields[1].selected
    //                   ? value.fields[1].value
    //                   : value.fields[0].value
    //                 : ''),
    //             (value['errorText'] = '')
    //           ),
    //         ),
    //       );
    //       props.setRoomesN(new Object(response.data.fields));
    //       // props.roomesN !== [] && console.log(props.roomesN);
    //       props.setBlockN([...response.data.blocks[0].block.cols]);
    //       setTimeout(() => {
    //         props.runModalShow(0, 'newAddress');
    //       }, 300);
    //     }
    //   });
  };
  const handlePageClick = (e) => {};

  return (
    <div className="content d-flex m-f-column">
      <SideNav
        menu={props.menu}
        profile={props.profile}
        submit={props.submit}
        setStatement={props.setStatement}
      />
      <div className="content__right">
        {props.errTextApi !== '' && (
          <div
            className="form__item form__err"
            dangerouslySetInnerHTML={{ __html: props.errTextApi }}></div>
        )}
        <div className="content__page">
          <div className="current">
            <div className="current-title">
              Адреса
              {props.addressList?.length > 0 && <span>{props.addressList?.length} из 5</span>}
            </div>
            <div className="current-items active">
              {props.addressList?.length > 0 ? (
                props.addressList?.map((listItem, index) => {
                  return (
                    <div className="current_item current_item-address" key={'address_' + index}>
                      <div className="current_item-address-left">
                        {listItem.list?.map((listPersonal, index) => {
                          return (
                            <div
                              className="current_item-address-personal"
                              key={'current_item-personal-' + index}>
                              <span>{listPersonal.name}</span>
                              {listPersonal.value}
                            </div>
                          );
                        })}
                      </div>
                      <div className="current_item-address-right">
                        <div className="current_item-address-title">
                          {listItem.default && (
                            <div className="current_item-address-main">Основной адрес</div>
                          )}
                          <span>{listItem.title}</span>
                        </div>
                        <div
                          className="current_item-address-edit"
                          onClick={() => (
                            props.setErrTextApi(''),
                            localStorage.setItem('editAddressId', listItem.id),
                            form()
                          )}>
                          Редактировать <span className="ico ico-edit"></span>
                        </div>
                        <div className="current_item-address-remove">
                          <span
                            className="ico ico-remove"
                            onClick={() => (
                              props.setErrTextApi(''),
                              localStorage.setItem('removeAddressId', listItem.id),
                              props.runModalShow(1, 'newAddress')
                            )}></span>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="current-items-null">У Вас пока нет сохраненных адресов:(</div>
              )}
              <span className="d-flex d-f-column text-center current-items-bottom-btn">
                <div
                  className="btn btn-blue2"
                  onClick={() => (form(), localStorage.removeItem('removeAddressId'))}>
                  Добавить адрес
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="modals__list">
        <Modal
          class={'max'}
          modalShow={props.modalShow}
          modalClose={props.modalClose}
          modalData={props.modals[0]}
          key={props.modals[0].id}>
          {props.errTextApi !== '' && (
            <div
              className="form__item form__err"
              dangerouslySetInnerHTML={{ __html: props.errTextApi }}></div>
          )}
          <div className="modal__box-header d-flex align-items-end justify-content-between">
            <div className="form-title ">
              {localStorage.getItem('editAddressId')
                ? 'Редактирование адреса'
                : 'Добавить Новый Адрес'}
            </div>
          </div>
          {props.blockN.length > 0 && (
            <div className="d-flex form__item-modal-form">
              <Input
                blockN={props.blockN}
                rooms={props.roomesN}
                setRooms={props.setRoomesN}
                setValidation={props.setValidation}
                togglePass={props.togglePass}
                handleroom={props.handleroom}
              />
            </div>
          )}

          <div className="d-flex modal-box-btn">
            <button
              onClick={() => {
                props.modalClose();
                localStorage.getItem('editAddressId') && localStorage.removeItem('editAddressId');
              }}
              className="btn btn-grey2 btn-inline">
              Отмена
            </button>
            <button
              onClick={() => {
                localStorage.getItem('editAddressId')
                  ? props.submit('addressUpdate')
                  : props.submit('addressAdd');
              }}
              className={'btn btn-inline ' + (!props.subActive ? ' btn-grey' : ' btn-blue2')}>
              Сохранить адрес
            </button>
          </div>
        </Modal>
        <Modal
          class={'min'}
          modalShow={props.modalShow}
          modalClose={props.modalClose}
          modalData={props.modals[1]}
          key={props.modals[1].id}>
          <div className="form__title-min">
            Вы действительно хотите <br />
            удалить адрес?
          </div>
          <div className="d-flex modal-box-btn">
            <button
              onClick={() => {
                props.modalClose();
              }}
              className="btn btn-grey2 btn-inline">
              Отмена
            </button>
            <button
              onClick={() => {
                localStorage.getItem('removeAddressId') &&
                  props.submit('removeAddress', localStorage.getItem('removeAddressId'));
              }}
              className="btn btn-blue2 btn-inline">
              Удалить
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}
