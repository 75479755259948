import React, { useState, useEffect } from 'react';
export default function App(props) {
  useEffect(() => {
    document.body.classList.add('home');
  }, []);

  return (
    <div className="content__max">
      <div className="content__page">
        <div className="form text-center d-flex d-aling-center d-f-column">
          <div className="form-title ">Ваш пароль изменен!</div>
          <div className="form__text">
            <p className="text-center">Спасибо за изменение пароля</p>
            <p></p>
          </div>
          <button
            onClick={() => {
              window.location.replace('/login');
            }}
            className="btn btn-blue2">
            Перейти к авторизации
          </button>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
