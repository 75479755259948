import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Registration from './Registration';
import EndRegistration from './EndRegistration';
import ForgonLogin from './ForgonLogin';
import Login from './Login';
import Confirm from './Confirm';
import Order from './Order';
import Return from './Return';
import Address2 from './Address';
import Address from './Address2';
import Personal from './Personal';
import AddAddress from './AddAddress';
import Home from './Home';
import PasswordNew from './PasswordNew';
import '../assets/css/App.scss';
require('isomorphic-fetch');
const agree = "document.getElementById('agree').click();";

const proff = {
  auth_key: '',
  name: '',
  avatar: {
    isChosen: false,
    isReady: false,
    isUploaded: false,
    option_id: 1,
  },
  product: {
    isUploaded: false,
    image: '',
    preview: '',
    status: {
      id: 1,
      name: '',
    },
  },
};
const API_URL = '/api/client';
// const API_URL = 'https://abbott.morizolabs.ru/api/client';
// let submit_enable = false;
export default function Main() {
  const [roomes, setRoomes] = useState([]);
  const [roomesN, setRoomesN] = useState([]);
  const [blockN, setBlockN] = useState([]);
  const [roomesH, setRoomesH] = useState([]);
  const [profile, setProfile] = useState(proff);
  const [addresslist, setAddressList] = useState([]);
  const [avatar, setAvatar] = useState([]);
  const [modalShow, setModalShow] = useState(null);
  const [menuItemsActive, setMenuItemsActive] = useState(null);
  const [menuFooterItemsActive, setMenuFooterItemsActive] = useState(null);
  const [nav, setNav] = useState(false);
  const [subActive, setSubActive] = useState(false);
  const [subActive2, setSubActive2] = useState(false);
  const [statement, setStatement] = useState(false);
  const [errTextApi, setErrTextApi] = useState('');
  const [errProduct, setErrProduct] = useState('');
  const [waitingTime, setWaitingTime] = useState(0);
  const [waitingTimeS, setWaitingTimeS] = useState(0);
  const [menu, setMenu] = useState([]);
  const [menuTop, setMenuTop] = useState([
    {
      url: 'https://freestylediabetes.ru/product/freestyle-libre ',
      name: 'Продукты',
      items: [
        {
          url: 'https://freestylediabetes.ru/product/freestyle-libre ',
          name: 'FreeStyle Libre',
        },
        {
          url: 'https://freestylediabetes.ru/',
          name: 'FreeStyle Libre 2',
        },
      ],
    },
    {
      url: 'https://school.freestylediabetes.ru/',
      name: 'Школа хороших сахаров',
      items: [
        {
          url: 'https://school.freestylediabetes.ru/newbie',
          name: 'Школа хороших сахаров для начинающих',
        },
        {
          url: 'https://school.freestylediabetes.ru/advanced',
          name: 'Школа хороших сахаров для продвинутых',
        },
      ],
    },
    {
      url: 'https://freestylediabetes.ru/reviews/',
      name: 'Отзывы',
    },
    {
      url: 'https://freestylediabetes.ru/faq/',
      name: 'Помощь',
      items: [
        {
          url: 'https://freestylediabetes.ru/howtoapply/',
          name: 'Установка датчика',
        },
      ],
    },
    {
      url: 'https://freestylediabetes.ru/active/',
      name: 'FREESTYLE АКТИВ',
    },
  ]);
  const [product, setProduct] = useState([]);
  const [step, setStep] = useState([]);
  const formInput = {
    id: 1,
    name: 'shareURL',
    nameForm: 'url',
    value: '',
    placeholder: '',
    room: '',
    type: 'text',
    validation: true,
    errorText: 'incorrect url',
    err: false,
  };
  const formInput2 = {
    editPass: [
      {
        id: 5,
        name: 'password',
        nameForm: 'password_current',
        value: '',
        room: '',
        type: 'password',
        typeMain: 'password',
        label: 'Текущий пароль',
        validation: true,
        errorText: 'Обязательное поле',
        err: false,
        togglePass: true,
        toggleActive: false,
      },
      {
        id: 5,
        name: 'password',
        nameForm: 'password',
        value: '',
        room: '',
        type: 'password',
        typeMain: 'password',
        label: 'Новый пароль',
        validation: true,
        errorText: 'Обязательное поле',
        err: false,
        togglePass: true,
        toggleActive: false,
      },
      {
        id: 9,
        name: 'password2',
        nameForm: 'password_repeat',
        value: '',
        room: '',
        type: 'password',
        typeMain: 'password',
        label: 'Подтвердите новый пароль',
        validation: true,
        errorText: 'Пароли не совпадают',
        err: false,
        togglePass: true,
        toggleActive: false,
      },
    ],
    feedack: [
      {
        id: 1,
        name: 'name',
        nameForm: 'feedack',
        value: '',
        placeholder: 'Ваше имя',
        room: '',
        type: 'text',
        validation: true,
        errorText: 'Имя не заполнено',
        err: false,
      },
      {
        id: 2,
        name: 'email',
        nameForm: 'feedack',
        value: '',
        placeholder: 'Ваше E-mail',
        room: '',
        type: 'email',
        validation: true,
        errorText: 'E-mail не заполнено',
        err: false,
      },
      {
        id: 3,
        name: 'subject_id',
        nameForm: 'feedack',
        value: 'Регистрация в акции',
        valId: null,
        placeholder: 'Регистрация в акции',
        room: '',
        type: 'select',
        errorText: 'Тема не выбрана',
        item: [
          {
            id: 1,
            name: 'Регистрация в акции',
          },
          {
            id: 2,
            name: 'Регистрация чека',
          },
          {
            id: 3,
            name: 'Призовой фонд',
          },
          {
            id: 4,
            name: 'Вопросы о продукте',
          },
          {
            id: 5,
            name: 'Технические вопросы',
          },
          {
            id: 6,
            name: 'Другое',
          },
        ],
        validation: true,
        errorText: 'Фамилия не заполнено',
        err: false,
      },
      {
        id: 4,
        name: 'text',
        nameForm: 'feedack',
        value: '',
        placeholder: 'Текст обращения',
        room: '',
        type: 'textarea',
        validation: true,
        errorText: 'Обращение не заполнено',
        err: false,
      },
      {
        id: 5,
        name: 'accept1',
        nameForm: 'feedack',
        value: false,
        label: 'Мне исполнилось 18 лет',
        placeholder: '',
        room: '',
        type: 'checkbox',
        validation: true,
        errorText: 'Проверка возраста',
        err: false,
      },
    ],
    changeCash: [
      {
        id: 1,
        name: 'last_name',
        nameForm: 'last_name',
        value: '',
        label: 'Фамилия',
        type: 'text',
        validation: true,
        errorText: 'Обязательное поле',
        err: false,
      },
      {
        id: 2,
        name: 'name',
        nameForm: 'name',
        value: '',
        type: 'text',
        label: 'Имя',
        validation: true,
        errorText: 'Обязательное поле',
        err: false,
      },
      {
        id: 3,
        name: 'second_name',
        nameForm: 'second_name',
        value: '',
        type: 'text',
        label: 'Отчество',
        validation: true,
        errorText: 'Обязательное поле',
        err: false,
      },
      {
        id: 4,
        name: 'number_account',
        nameForm: 'number_account',
        value: '',
        type: 'text',
        label: 'Номер личного счета получателя в банке',
        validation: true,
        errorText: 'Обязательное поле',
        err: false,
      },
      {
        id: 5,
        name: 'bik',
        nameForm: 'bik',
        value: '',
        type: 'text',
        label: 'БИК',
        validation: true,
        errorText: 'Обязательное поле',
        err: false,
      },
      {
        id: 6,
        name: 'bank',
        nameForm: 'bank',
        value: '',
        label: 'Наименование банка',
        type: 'text',
        validation: true,
        errorText: 'Обязательное поле',
        err: false,
      },
    ],
    changeCashAggre: [
      {
        id: 6,
        name: 'agree',
        nameForm: 'agree',
        value: false,
        label:
          'Установив галочку в данном поле, Вы даете Abbott свое согласие на обработку персональных данных и подтверждаете, что Вы ознакомились и согласны с нашей <a href="javascript:void(0);" onclick=' +
          agree +
          '>Политикой конфиденциальности</a>',
        placeholder: '',
        room: '',
        type: 'checkbox',
        validation: true,
        errorText: 'Обязательное поле',
        err: false,
      },
    ],
    registr: [
      {
        id: 1,
        name: 'name',
        nameForm: 'name',
        value: '',
        placeholder: 'form.name.place',
        type: 'text',
        validation: true,
        errorText: 'form.name.errText',
        err: false,
      },
      {
        id: 8,
        name: 'gender',
        nameForm: 'gender',
        value: '',
        valId: null,
        placeholder: 'form.gender.place',
        room: '',
        type: 'select',
        errorText: 'form.gender.place',
        item: [
          {
            id: 0,
            name: 'form.gender.female',
          },
          {
            id: 1,
            name: 'form.gender.male',
          },
        ],
        validation: true,
        err: false,
      },
      {
        id: 2,
        name: 'region_id',
        nameForm: 'region_id',
        value: '',
        valId: null,
        placeholder: 'form.region_id.place',
        room: '',
        type: 'select',
        errorText: 'form.region_id.place',
        item: [
          {
            id: 1,
            name: 'form.region_id.UAE',
          },
          {
            id: 2,
            name: 'form.region_id.KSA',
          },
          {
            id: 3,
            name: 'form.region_id.Kuwait',
          },
        ],
        validation: true,
        err: false,
      },
      {
        id: 3,
        name: 'email',
        nameForm: 'login',
        value: '',
        placeholder: 'form.email.place',
        room: '',
        type: 'email',
        validation: true,
        errorText: 'form.email.place',
        err: false,
      },
      {
        id: 5,
        name: 'password',
        nameForm: 'password',
        value: '',
        placeholder: 'form.password.place',
        room: '',
        type: 'password',
        typeMain: 'password',
        validation: true,
        errorText: 'form.password.place',
        err: false,
        togglePass: true,
        toggleActive: false,
      },
      {
        id: 9,
        name: 'password2',
        nameForm: 'none',
        value: '',
        placeholder: 'form.password2.place',
        room: '',
        type: 'password',
        typeMain: 'password',
        validation: true,
        errorText: 'form.password2.place',
        err: false,
        togglePass: true,
        toggleActive: false,
      },
      {
        id: 6,
        name: 'agree',
        nameForm: 'agree',
        value: false,
        label:
          'form.agree.place' +
          '<a href="javascript:void(0);" onClick="' +
          agree +
          '">' +
          'form.agree.place2' +
          '</a>',
        placeholder: '',
        room: '',
        type: 'checkbox',
        validation: true,
        errorText: 'form.agree.place',
        err: false,
      },
    ],
    registr2: [
      {
        name: 'name',
        value: '',
        placeholder: 'form.name.place',
        type: 'text',
        errorText: 'form.name.errText',
      },
      {
        name: 'gender',
        value: '',
        placeholder: 'form.gender.place',
        type: 'select',
        errorText: 'form.gender.place',
        item: [
          {
            id: 0,
            name: 'form.gender.female',
          },
          {
            id: 1,
            name: 'form.gender.male',
          },
        ],
      },
      {
        id: 5,
        name: 'password',
        value: '',
        placeholder: 'form.password.place',
        type: 'password',
        typeMain: 'password',
        errorText: 'form.password.place',
      },
      {
        id: 9,
        name: 'password2',
        value: '',
        placeholder: 'form.password2.place',
        type: 'password',
        typeMain: 'password',
        validation: true,
        errorText: 'form.password2.place',
      },
      {
        id: 6,
        name: 'agree',
        value: false,
        title:
          'form.agree.place' +
          '<a href="javascript:void(0);" onClick="' +
          agree +
          '">' +
          'form.agree.place2' +
          '</a>',
        placeholder: '',
        type: 'checkbox',
        errorText: 'form.agree.place',
      },
    ],
    // old: [
    //   data: [
    //     {
    //       title: '',
    //       comments: '',
    //       type: 'dropdown',
    //       fields: [
    //         {
    //           name: '',
    //           title: '',
    //           value: '',
    //           type: 'dropdown',
    //           selected: false,
    //           param: {
    //             required: false,
    //             readonly: false
    //           }
    //         }
    //       ],
    //     },
    //     {
    //       title: '',
    //       comments: '',
    //       fields: [
    //         {
    //           name: '',
    //           title: '',
    //           value: '',
    //           type: 'text',
    //           param: {
    //             required: false,
    //             readonly: false
    //           }
    //         }
    //       ],
    //     },
    //     {
    //       title: '',
    //       comments: '',
    //       fields: [
    //         {
    //           name: '',
    //           title: '',
    //           value: '',
    //           type: 'email',
    //           param: {
    //             required: true,
    //             readonly: false
    //           }
    //         }
    //       ],
    //     },
    //     {
    //       title: '',
    //       comments: '',
    //       fields: [
    //         {
    //           name: '',
    //           title: '',
    //           value: '',
    //           type: 'checkbox',
    //           selected: false,
    //           param: {
    //             required: true,
    //             readonly: false
    //           }
    //         }
    //       ],
    //     },
    //     {
    //       title: 'Рассылки',
    //       comments: '',
    //       fields: [
    //         {
    //           name: '',
    //           title: '',
    //           value: '1',
    //           type: 'checkbox',
    //           selected: false,
    //           param: {
    //             required: false,
    //             readonly: false
    //           }
    //         },
    //         {
    //           name: '',
    //           title: '',
    //           value: '2',
    //           type: 'checkbox',
    //           selected: false,
    //           param: {
    //             required: false,
    //             readonly: false
    //           }
    //         }
    //       ],
    //     },
    //   ],
    // ],
    login: [
      {
        id: 3,
        name: 'email',
        nameForm: 'email',
        value: '',
        placeholder: '',
        room: '',
        label: 'Адрес электронной почты',
        type: 'email',
        validation: true,
        errorText: 'Поле обязательно для заполнения',
        err: false,
      },
      {
        id: 5,
        name: 'password',
        nameForm: 'password',
        value: '',
        placeholder: '',
        room: '',
        label: 'Пароль',
        type: 'password',
        validation: true,
        errorText: 'Поле обязательно для заполнения',
        err: false,
        togglePass: true,
        btnForgonLogin: true,
      },
    ],
    forgonLogin: [
      {
        id: 5,
        name: 'password',
        nameForm: 'new_password',
        value: '',
        label: 'Введите новый пароль',
        room: '',
        type: 'password',
        typeMain: 'password',
        validation: true,
        errorText: 'Поле обязательно для заполнения',
        err: false,
        togglePass: true,
        toggleActive: false,
      },
      {
        id: 9,
        name: 'password2',
        nameForm: 'none',
        value: '',
        label: 'Подтвердите новый пароль',
        room: '',
        type: 'password',
        typeMain: 'password',
        validation: true,
        errorText: 'Пароли не совпадают',
        err: false,
        togglePass: true,
        toggleActive: false,
      },
    ],
    forEmail: [
      {
        id: 3,
        name: 'email',
        nameForm: 'email',
        value: '',
        placeholder: '',
        room: '',
        label: 'Адрес электронной почты',
        type: 'email',
        validation: true,
        errorText: 'Поле обязательно для заполнения',
        err: false,
      },
    ],
  };
  const Modals = [
    { id: 0 },
    { id: 1, type: 'removeAddress' },
    { id: 2, type: 'activation' },
    { id: 3, type: 'forgonLogin' },
    { id: 4 },
    { id: 5, type: 'instruction' },
    { id: 6, type: 'aggre' },
    { id: 7, type: 'status_statement' },
    { id: 8, name: 'modal.Confirm', title: 'отправка фото' },
    { id: 9, name: 'modal.emailToEmail' },
  ];

  const changeLang = () => {
    setTimeout(() => {
      setProduct([
        { id: 1, title: 'product.1.title', text: 'product.1.text' },
        { id: 2, title: 'product.2.title', text: 'product.2.text' },
        { id: 3, title: 'product.3.title', text: 'product.3.text' },
        { id: 4, title: 'product.4.title', text: 'product.4.text' },
        { id: 5, title: 'product.5.title', text: 'product.5.text' },
        { id: 6, title: 'product.6.title', text: 'product.6.text' },
        { id: 7, title: 'product.7.title', text: 'product.7.text' },
        { id: 8, title: 'product.8.title', text: 'product.8.text' },
        { id: 9, title: 'product.9.title', text: 'product.9.text' },
        { id: 10, title: 'product.10.title', text: 'product.10.text' },
        { id: 11, title: 'product.11.title', text: 'product.11.text' },
        { id: 12, title: 'product.12.title', text: 'product.12.text' },
        { id: 13, title: 'product.13.title', text: 'product.13.text' },
        { id: 14, title: 'product.14.title', text: 'product.14.text' },
        { id: 15, title: 'product.15.title', text: 'product.15.text' },
        { id: 16, title: 'product.16.title', text: 'product.16.text' },
        { id: 17, title: 'product.17.title', text: 'product.17.text' },
        { id: 18, title: 'product.18.title', text: 'product.18.text' },
        { id: 19, title: 'product.19.title', text: 'product.19.text' },
        { id: 20, title: 'product.20.title', text: 'product.20.text' },
        { id: 21, title: 'product.21.title', text: 'product.21.text' },
        { id: 22, title: 'product.22.title', text: 'product.22.text' },
        { id: 23, title: 'product.23.title', text: 'product.23.text' },
        { id: 24, title: 'product.24.title', text: 'product.24.text' },
        { id: 25, title: 'product.25.title', text: 'product.25.text' },
        { id: 26, title: 'product.26.title', text: 'product.26.text' },
        { id: 27, title: 'product.27.title', text: 'product.27.text' },
      ]);
      setStep([
        { id: 1, text: 'step.1' },
        {
          id: 2,
          text: 'step.2',
        },
        {
          id: 3,
          text: 'step.3',
        },
      ]);
    }, 30);
  };
  useEffect(() => {
    // runModalShow(4, 'login');
    // runModalShow(7);
    // runModalShow(2, 'feedack2');
    localStorage.getItem('token') && getProfile();
    // // console.log(formInput2['registr']);
    // setProfile(JSON.parse(localStorage.getItem('profile')));
    // setAvatar(JSON.parse(localStorage.getItem('avatar')));
    // changeLang();
    // setRoomesH([formInput]);
    // getRegistration();
    getMenu();
  }, []);
  const setValidation = (error, is, errorText = '') => {
    setRoomes((room) =>
      room?.map((list, index) =>
        index === is
          ? { ...list, err: error, errorText: errorText !== '' ? errorText : list.errorText }
          : list,
      ),
    );
  };
  const setValidationS = (error, is) => {
    setRoomesN((room) =>
      room?.map((list, index) => (index === is ? { ...list, err: error } : list)),
    );
  };
  const validationS = (type, value, id, name, passVal) => {
    let submit_enable = false;
    switch (type) {
      case 'email':
        if (value === '') {
          setValidationS(true, id);
          submit_enable = true;
        } else {
          let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!re.test(value)) {
            setValidationS(true, id);
          } else {
            setValidationS(false, id);
          }
        }
        break;
      case 'checkbox':
        if (value === false) {
          setValidationS(true, id);
          submit_enable = true;
        } else {
          setValidationS(false, id);
        }
        break;
      case 'select':
        if (value === '') {
          setValidationS(true, id);
          submit_enable = true;
        } else {
          setValidationS(false, id);
        }
        break;
      case 'password':
        if (name === 'password') {
          if (value === '') {
            setValidationS(true, id);
            submit_enable = true;
          } else {
            setValidationS(false, id);
          }
        } else {
          if (value !== passVal) {
            setValidationS(true, id);
            submit_enable = true;
          } else {
            setValidationS(false, id);
          }
        }
        break;
      default:
        if (value === '') {
          setValidationS(true, id);
          submit_enable = true;
        } else {
          setValidationS(false, id);
        }
        break;
    }
    return submit_enable;
  };
  const validation = (type, value, id, name, passVal) => {
    let submit_enable = false;
    switch (type) {
      case 'email':
        if (value === '') {
          setValidation(true, id);
          submit_enable = true;
        } else {
          let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!re.test(value)) {
            setValidation(true, id, 'E-mail не правильно написан');
          } else {
            setValidation(false, id);
          }
        }
        break;
      case 'checkbox':
        if (value === false) {
          setValidation(true, id);
          submit_enable = true;
        } else {
          setValidation(false, id);
        }
        break;
      case 'select':
        if (value === '') {
          setValidation(true, id);
          submit_enable = true;
        } else {
          setValidation(false, id);
        }
        break;
      case 'password':
        if (name === 'password') {
          if (value === '') {
            setValidation(true, id);
            submit_enable = true;
          } else {
            setValidation(false, id);
          }
        } else {
          if (value !== passVal) {
            setValidation(true, id);
            submit_enable = true;
          } else {
            setValidation(false, id);
          }
        }
        break;
      case 'phone':
        if (value === '' || value === null) {
          setValidationB(true, id);
          submit_enable = true;
        } else {
          if (value.split('_')[0].length < 12) {
            setValidationB(true, id);
            submit_enable = true;
          } else {
            setValidationB(false, id);
          }
        }
        break;
      default:
        if (value === '') {
          setValidation(true, id);
          submit_enable = true;
        } else {
          setValidation(false, id);
        }
        break;
    }
    return submit_enable;
  };
  // для проверки новых полей
  const togglePassN = (id, vId) => {
    let copy = [];
    copy = Object.assign([], roomesN);
    copy[id].fields[vId].type = copy[id].fields[vId].type === 'password' ? 'text' : 'password';
    setRoomesN(copy);
  };
  const submitN = (formName, subData, subData2) => {
    let subActive = false;
    let names = [];
    let passVal = '';
    let passId = '';
    let formData = new FormData();
    switch (formName) {
      case 'feedack':
        roomes?.map(
          (inpItem, index) =>
            inpItem.validation &&
            validation(inpItem.type, inpItem.value, index) === true &&
            (subActive = true),
        );
        if (!subActive) {
          roomes?.map((inpItem, index) => {
            switch (inpItem.type) {
              case 'checkbox':
                formData.append(inpItem.name, 1);
                break;
              case 'select':
                formData.append(inpItem.name, inpItem.valId);
                break;
              default:
                formData.append(inpItem.nameForm, inpItem.value);
                break;
            }
          });
          fetch(API_URL + '/sendFeedback', {
            method: 'POST',
            body: formData,
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.result === true) {
                setModalShow(3);
              } else {
                errValid(response.error);
              }
            });
        }

        break;
      case 'registr':
        setSubActive(true);
        let passVal2 = '';
        roomesN?.map((inpItems, index) => {
          if (
            inpItems.fields[0].name === 'password' ||
            inpItems.fields[0].name === 'password_confirm'
          ) {
            let copy = [];
            copy = Object.assign([], roomesN);
            copy[index].fields[0].type =
              copy[index].fields[0].type !== 'password' ? 'password' : 'password';
            setRoomesN(copy);
          }
          inpItems.fields?.map((inpItem, inpIndex) => {
            switch (inpItem.name) {
              case 'password':
                passVal2 = inpItem.value;
                inpItem.param.required &&
                  validationN(inpItem.type, inpItem.value, index, inpItem.name) === true &&
                  (subActive = true);
                break;
              case 'agree':
                inpItem.param.required &&
                  validationN(inpItem.type, inpItem.selected, index, inpItem.name) === true &&
                  (subActive = true);
                break;
              case 'password_confirm':
                inpItem.param.required &&
                  validationN(inpItem.type, inpItem.value, index, inpItem.name, passVal2) ===
                    true &&
                  (subActive = true);
                break;

              default:
                inpItem.param.required &&
                  validationN(inpItem.type, inpItem.value, index, inpItem.name) === true &&
                  (subActive = true);
                break;
            }
          });
        });
        if (!subActive) {
          let mass = {};
          var myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');
          setSubActive(true);
          let checkMass = [];
          roomesN?.map((item, index) => {
            item.fields?.map((inpItem, index) => {
              switch (inpItem.type) {
                case 'select' && 'dropdown':
                  index == '0' && (mass[inpItem.name] = item.value);
                  break;
                case 'checkbox_simple':
                  formData.append(inpItem.name, inpItem.selected);
                  break;
                case 'checkbox':
                  inpItem.selected && checkMass.push(inpItem.value);
                  break;
                default:
                  inpItem.type === 'email' && localStorage.setItem('email', inpItem.value);
                  inpItem.value !== null && (mass[inpItem.name] = inpItem.value);
                  break;
              }
            });
          });
          checkMass && checkMass !== '' && formData.append('subscribe', checkMass);
          mass['agree'] = '1';
          mass['subscribe'] = checkMass;
          fetch(API_URL + '/registration', {
            method: 'POST',
            body: JSON.stringify(mass),
            headers: myHeaders,
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
                window.location.replace('/end-registration');
              } else {
                setSubActive(false);
                window.grecaptcha.reset();
                setErrTextApi(response.errors);
              }
            });
        } else {
          setSubActive(false);
        }

        break;
      case 'editProfile':
        setSubActive(true);
        Object.entries(roomesN)?.map((inpItems, index) => {
          inpItems[1].fields?.map((inpItem, inpIndex) => {
            switch (inpItem.name) {
              case 'password':
                passVal = inpItem.value;
                inpItem.param.required &&
                  validationB(inpItem.type, inpItem.value, index, inpItem.name, '', inpItems[0]) ===
                    true &&
                  (subActive = true);
                break;
              case 'agree':
                inpItem.param.required &&
                  validationB(
                    inpItem.type,
                    inpItem.selected,
                    index,
                    inpItem.name,
                    '',
                    inpItems[0],
                  ) === true &&
                  (subActive = true);
                break;
              case 'password_confirm':
                inpItem.param.required &&
                  validationB(
                    inpItem.type,
                    inpItem.value,
                    index,
                    inpItem.name,
                    passVal,
                    inpItems[0],
                  ) === true &&
                  (subActive = true);
                break;

              default:
                inpItem.param.required &&
                  validationB(inpItem.type, inpItem.value, index, inpItem.name, '', inpItems[0]) ===
                    true &&
                  (subActive = true);
                break;
            }
          });
        });
        if (!subActive) {
          let mass = {};
          var myHeaders = new Headers();
          myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
          myHeaders.append('Content-Type', 'application/json');
          setSubActive(true);
          let checkMass = [];
          Object.entries(roomesN)?.map((item, index) => {
            item[1].fields?.map((inpItem, index) => {
              switch (inpItem.type) {
                case 'select' && 'dropdown':
                  index == '0' && (mass[inpItem.name] = item[1].value);
                  break;
                case 'checkbox_simple':
                  mass[inpItem.name] = inpItem.value;
                  break;
                case 'checkbox':
                  inpItem.selected && checkMass.push(inpItem.value);
                  break;
                default:
                  inpItem.type === 'email' && localStorage.setItem('email', inpItem.value);
                  inpItem.value !== null && (mass[inpItem.name] = inpItem.value);
                  break;
              }
            });
          });
          mass['subscribe'] = checkMass;
          fetch(API_URL + '/profile/save', {
            method: 'POST',
            body: JSON.stringify(mass),
            headers: myHeaders,
          }).then((response) => {
            if (response.status === 401) {
              localStorage.removeItem('token');
              window.location.replace('/login');
            } else {
              response.json().then(function (data) {
                if (data.status === true) {
                  getProfile();
                } else {
                  setErrTextApi(data.errors);
                  setSubActive(false);
                }
              });
            }
          });
        } else {
          setSubActive(false);
        }

        break;
      case 'savePassword':
        setSubActive2(true);
        names = roomes.map((el) => el.nameForm);
        passId = names.indexOf('password');
        passVal = roomes[passId].value;
        roomes?.map((inpItem, index) => {
          if (inpItem.typeMain === 'password' && inpItem.type === 'text') {
            roomes[index].type = 'password';
            roomes[index].toggleActive = !roomes[index].toggleActive;
          }
          switch (inpItem.name) {
            case 'password2':
              inpItem.validation &&
                validation(inpItem.type, inpItem.value, index, inpItem.name, passVal) === true &&
                (subActive = true);
              break;

            default:
              inpItem.validation &&
                validation(inpItem.type, inpItem.value, index, inpItem.name) === true &&
                (subActive = true);
              break;
          }
        });
        if (!subActive) {
          let mass = {};
          var myHeaders = new Headers();
          myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
          myHeaders.append('Content-Type', 'application/json');
          setSubActive2(true);
          let checkMass = [];
          roomes?.map((item, index) => {
            item.value !== null && (mass[item.nameForm] = item.value);
          });
          fetch(API_URL + '/profile/save-password', {
            method: 'POST',
            body: JSON.stringify(mass),
            headers: myHeaders,
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
                // повторная авторизация
                formData.append('email', profile?.[0]?.email);
                formData.append('password', mass['password']);
                fetch(API_URL + '/authentication', {
                  method: 'POST',
                  body: formData,
                })
                  .then((response) => response.json())
                  .then((response) => {
                    if (response.status === true) {
                      localStorage.setItem('token', response.data.token);
                      // window.location.replace('/personal');
                    } else {
                      setErrTextApi(response.errors);
                      window.location.replace('/login');
                    }
                  });
              } else {
                setErrTextApi(response.errors);
                setSubActive2(false);
              }
            });
        } else {
          setSubActive2(false);
        }

        break;
      case 'rmaUpdate':
        setSubActive(true);
        !localStorage.getItem('rmaId') ||
          (localStorage.getItem('rmaId') === '' && setErrTextApi('Не выбрана заявка'));
        (!localStorage.getItem('rmaAddressId') || localStorage.getItem('rmaAddressId') === '') &&
          setErrTextApi('Не выбран адрес');
        (!localStorage.getItem('rmaAddressId') || localStorage.getItem('rmaAddressId') === '') &&
          (subActive = true);

        localStorage.getItem('rmaTypeReturnMoney') &&
          localStorage.getItem('rmaTypeReturnMoney') === 'return_money' &&
          localStorage.getItem('typeReturnMoney') === 'transfer' &&
          roomes?.map((inpItem, index) => {
            switch (inpItem.name) {
              case 'password2':
                inpItem.validation &&
                  validation(inpItem.type, inpItem.value, index, inpItem.name, passVal) === true &&
                  (subActive = true);
                break;

              default:
                inpItem.validation &&
                  validation(inpItem.type, inpItem.value, index, inpItem.name) === true &&
                  (subActive = true);
                break;
            }
          });
        roomesH?.map((inpItem, index) => {
          switch (inpItem.name) {
            case 'password2':
              inpItem.validation &&
                validationH(inpItem.type, inpItem.value, index, inpItem.name, passVal) === true &&
                (subActive = true);
              break;

            default:
              inpItem.validation &&
                validationH(inpItem.type, inpItem.value, index, inpItem.name) === true &&
                (subActive = true);
              break;
          }
        });

        if (!subActive) {
          let mass = {};
          var myHeaders = new Headers();
          myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
          myHeaders.append('Content-Type', 'application/json');
          setSubActive(true);
          let checkMass = [];
          if (
            localStorage.getItem('rmaTypeReturnMoney') &&
            localStorage.getItem('rmaTypeReturnMoney') === 'return_money'
          ) {
            roomes?.map((item, index) => {
              item.value !== null && (mass[item.nameForm] = item.value);
            });
            mass['typeReturnMoney'] = localStorage.getItem('typeReturnMoney');
          }
          mass['id'] = localStorage.getItem('rmaId');
          mass['addressId'] = localStorage.getItem('rmaAddressId');
          fetch(API_URL + '/rma/update', {
            method: 'POST',
            body: JSON.stringify(mass),
            headers: myHeaders,
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
                window.scrollTo(0, 0);
                getProfile();
                setStatement(false);
                localStorage.removeItem('rmaId');
                localStorage.removeItem('rmaAddressId');
                localStorage.removeItem('typeReturnMoney');
                setErrTextApi('');
                runModalShow(7);
              } else {
                setErrTextApi(response.errors);
              }
            });
        } else {
          setSubActive(false);
        }

        break;
      case 'addressAdd':
        setSubActive(true);
        Object.entries(roomesN)?.map((inpItems, index) => {
          inpItems[1].fields?.map((inpItem, inpIndex) => {
            switch (inpItem.name) {
              case 'password':
                passVal = inpItem.value;
                inpItem.param.required &&
                  validationB(inpItem.type, inpItem.value, index, inpItem.name, '', inpItems[0]) ===
                    true &&
                  (subActive = true);
                break;
              case 'agree':
                inpItem.param.required &&
                  validationB(
                    inpItem.type,
                    inpItem.selected,
                    index,
                    inpItem.name,
                    '',
                    inpItems[0],
                  ) === true &&
                  (subActive = true);
                break;
              case 'password_confirm':
                inpItem.param.required &&
                  validationB(
                    inpItem.type,
                    inpItem.value,
                    index,
                    inpItem.name,
                    passVal,
                    inpItems[0],
                  ) === true &&
                  (subActive = true);
                break;

              default:
                inpItem.param.required &&
                  validationB(inpItem.type, inpItem.value, index, inpItem.name, '', inpItems[0]) ===
                    true &&
                  (subActive = true);
                break;
            }
          });
        });
        if (!subActive) {
          setSubActive(true);
          let mass = {};
          // Object.entries(roomesN)?.map((item, index) => {
          //   item[1].fields?.map((inpItem, index) => {
          //     switch (inpItem.type) {
          //       case 'select' && 'dropdown':
          //         index == '0' && formData.append(inpItem.name, item[1].value);
          //         break;
          //       case 'checkbox_simple':
          //         formData.append(inpItem.name, inpItem.selected);
          //         break;
          //       case 'checkbox':
          //         inpItem.selected && checkMass.push(inpItem.value);
          //         break;
          //       default:
          //         inpItem.type === 'email' && localStorage.setItem('email', inpItem.value);
          //         inpItem.value !== null && formData.append(inpItem.name, inpItem.value);
          //         break;
          //     }
          //   });
          // });
          Object.entries(roomesN)?.map((item, index) => {
            item[1].fields?.map((inpItem, index) => {
              switch (inpItem.type) {
                case 'select' && 'dropdown':
                  index == '0' && (mass[inpItem.name] = item[1].value);
                  break;
                case 'checkbox_simple':
                  mass[inpItem.name] = inpItem.value;
                  break;
                case 'checkbox':
                  mass[inpItem.name] = inpItem.selected;
                  break;
                default:
                  inpItem.type === 'email' && localStorage.setItem('email', inpItem.value);
                  inpItem.value !== null && (mass[inpItem.name] = inpItem.value);
                  break;
              }
            });
          });
          var myHeaders = new Headers();
          myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
          fetch(API_URL + '/address/add', {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(mass),
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
                submitN('addressList');
                modalClose();
                // window.location.replace('/end-registration');
              } else {
                setErrTextApi(response.errors);
                setSubActive(false);
              }
            });
        } else {
          setSubActive(false);
        }

        break;
      case 'addressUpdate':
        setSubActive(true);
        Object.entries(roomesN)?.map((inpItems, index) => {
          inpItems[1].fields?.map((inpItem, inpIndex) => {
            switch (inpItem.name) {
              case 'password':
                passVal = inpItem.value;
                inpItem.param.required &&
                  validationB(inpItem.type, inpItem.value, index, inpItem.name, '', inpItems[0]) ===
                    true &&
                  (subActive = true);
                break;
              case 'agree':
                inpItem.param.required &&
                  validationB(
                    inpItem.type,
                    inpItem.selected,
                    index,
                    inpItem.name,
                    '',
                    inpItems[0],
                  ) === true &&
                  (subActive = true);
                break;
              case 'password_confirm':
                inpItem.param.required &&
                  validationB(
                    inpItem.type,
                    inpItem.value,
                    index,
                    inpItem.name,
                    passVal,
                    inpItems[0],
                  ) === true &&
                  (subActive = true);
                break;

              default:
                inpItem.param.required &&
                  validationB(inpItem.type, inpItem.value, index, inpItem.name, '', inpItems[0]) ===
                    true &&
                  (subActive = true);
                break;
            }
          });
        });
        if (!subActive) {
          setSubActive(true);
          let mass = {};
          mass['id'] = localStorage.getItem('editAddressId');
          Object.entries(roomesN)?.map((item, index) => {
            item[1].fields?.map((inpItem, index) => {
              switch (inpItem.type) {
                case 'select' && 'dropdown':
                  index == '0' && (mass[inpItem.name] = item[1].value);
                  break;
                case 'checkbox_simple':
                  mass[inpItem.name] = inpItem.value;
                  break;
                case 'checkbox':
                  mass[inpItem.name] = inpItem.selected;
                  break;
                default:
                  inpItem.type === 'email' && localStorage.setItem('email', inpItem.value);
                  inpItem.value !== null && (mass[inpItem.name] = inpItem.value);
                  break;
              }
            });
          });
          var myHeaders = new Headers();
          myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
          fetch(API_URL + '/address/update', {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(mass),
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
                submitN('addressList');
                localStorage.getItem('editAddressId') && localStorage.removeItem('editAddressId');
                modalClose();
                // window.location.replace('/end-registration');
              } else {
                setErrTextApi(response.errors);
                setSubActive(false);
              }
            });
        } else {
          setSubActive(false);
        }

        break;
      case 'removeAddress':
        if (subData) {
          // formData.append('id', subData);
          var myHeaders = new Headers();
          myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
          fetch(API_URL + '/address/delete', {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({ id: subData }),
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
                submitN('addressList');
                modalClose();
                // window.location.replace('/home');
              } else {
                setErrTextApi(response.errors);
              }
            });
        }

        break;
      case 'forgonLogin':
        setSubActive(true);
        const names2 = roomes.map((el) => el.name);
        passId = names2.indexOf('password');
        passVal = roomes[passId].value;
        roomes?.map((inpItem, index) => {
          if (inpItem.typeMain === 'password' && inpItem.type === 'text') {
            roomes[index].type = 'password';
            roomes[index].toggleActive = !roomes[index].toggleActive;
          }
          switch (inpItem.name) {
            case 'password2':
              inpItem.validation &&
                validation(inpItem.type, inpItem.value, index, inpItem.name, passVal) === true &&
                (subActive = true);
              break;

            default:
              inpItem.validation &&
                validation(inpItem.type, inpItem.value, index, inpItem.name) === true &&
                (subActive = true);
              break;
          }
        });
        if (!subActive) {
          let mass = {};
          var myHeaders = new Headers();
          // myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
          myHeaders.append('Content-Type', 'application/json');
          setSubActive(true);
          let checkMass = [];
          roomes[0].value !== null && (mass[roomes[0].nameForm] = roomes[0].value);
          let urlParams = new URLSearchParams(window.location.search);
          urlParams.get('check_word') && (mass['check_word'] = urlParams.get('check_word'));
          urlParams.get('email') && (mass['email'] = urlParams.get('email'));
          fetch(API_URL + '/change-password', {
            method: 'POST',
            body: JSON.stringify(mass),
            headers: myHeaders,
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
                window.location.replace('/password-new');
              } else {
                setErrTextApi(response.errors);
              }
            });
        } else {
          setSubActive(false);
        }

        break;
      case 'addressList':
        var myHeaders = new Headers();
        myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
        fetch(API_URL + '/address/list', {
          method: 'GET',
          headers: myHeaders,
        }).then((response) => {
          if (response.status === 401) {
            localStorage.removeItem('token');
            window.location.replace('/login');
          } else {
            response.json().then(function (data) {
              if (data.status === true) {
                setAddressList([...data.data]);
              } else {
                console.log(data);
              }
            });
          }
        });

        break;
      case 'confirm_registration':
        setSubActive(true);
        roomesN?.map((inpItems, index) => {
          if (
            inpItems.fields[0].name === 'password' ||
            inpItems.fields[0].name === 'password_confirm'
          ) {
            let copy = [];
            copy = Object.assign([], roomesN);
            copy[index].fields[0].type =
              copy[index].fields[0].type !== 'password' ? 'password' : 'password';
            setRoomesN(copy);
          }
          inpItems.fields?.map((inpItem, inpIndex) => {
            switch (inpItem.name) {
              case 'password':
                passVal = inpItem.value;
                inpItem.param.required &&
                  validationN(inpItem.type, inpItem.value, index, inpItem.name) === true &&
                  (subActive = true);
                break;
              case 'agree':
                inpItem.param.required &&
                  validationN(inpItem.type, inpItem.selected, index, inpItem.name) === true &&
                  (subActive = true);
                break;
              case 'password_confirm':
                inpItem.param.required &&
                  validationN(inpItem.type, inpItem.value, index, inpItem.name, passVal) === true &&
                  (subActive = true);
                break;

              default:
                inpItem.param.required &&
                  validationN(inpItem.type, inpItem.value, index, inpItem.name) === true &&
                  (subActive = true);
                break;
            }
          });
        });
        if (!subActive) {
          let mass = {};
          var myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');
          setSubActive(true);
          let checkMass = [];
          roomesN?.map((item, index) => {
            item.fields?.map((inpItem, index) => {
              switch (inpItem.type) {
                case 'select' && 'dropdown':
                  index == '0' && (mass[inpItem.name] = item.value);
                  break;
                case 'checkbox_simple':
                  mass[inpItem.name] = inpItem.value;
                  break;
                case 'checkbox':
                  inpItem.selected && checkMass.push(inpItem.value);
                  break;
                default:
                  inpItem.type === 'email' && localStorage.setItem('email', inpItem.value);
                  inpItem.value !== null && (mass[inpItem.name] = inpItem.value);
                  break;
              }
            });
          });

          checkMass && checkMass !== '' && formData.append('subscribe', checkMass);
          formData.append('confirm_user_id', subData.get('confirm_user_id'));
          formData.append('need_agree', subData.get('need_agree'));
          formData.append('confirm_code', subData.get('confirm_code'));
          mass['confirm_user_id'] = subData.get('confirm_user_id');
          mass['need_agree'] = subData.get('need_agree');
          mass['confirm_code'] = subData.get('confirm_code');
          mass['subscribe'] = checkMass;
          fetch(API_URL + '/registration/email-confirm', {
            method: 'POST',
            body: JSON.stringify(mass),
            headers: myHeaders,
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
                window.location.replace('/auth/confirm_registration?need_agree=N');

                // setErrTextApi('')
                // runModalShow(9);
                // setModalShow(3);
                // response.data.link ? submi'activate', response.data.link) : setModalShow(3);
              } else {
                // errValid(response.error);
                runModalShow(2);
                setErrTextApi(response.errors);
                setSubActive(false);
              }
            });
        } else {
          setSubActive(false);
        }

        break;
      case 'login':
        setSubActive(true);
        roomes?.map(
          (inpItem, index) =>
            inpItem.validation &&
            validation(inpItem.type, inpItem.value, index, inpItem.name) === true &&
            (subActive = true),
        );
        if (!subActive) {
          roomes?.map((inpItem, index) => {
            switch (inpItem.type) {
              case 'checkbox':
                formData.append(inpItem.nameForm, 1);
                break;
              case 'select':
                formData.append(inpItem.nameForm, inpItem.valId);
                break;
              default:
                formData.append(inpItem.nameForm, inpItem.value);
                break;
            }
          });
          fetch(API_URL + '/authentication', {
            method: 'POST',
            body: formData,
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
                localStorage.setItem('token', response.data.token);
                localStorage.getItem('statemetId')
                  ? window.location.replace('/return?id=' + localStorage.getItem('statemetId'))
                  : window.location.replace('/home');
              } else {
                setErrTextApi(response.errors);
                setSubActive(false);
              }
            });
        } else {
          setSubActive(false);
        }

        break;
      case 'forgonLogin1':
        if (subData) {
          formData.append('email', subData);
          fetch(API_URL + '/change-password-request', {
            method: 'POST',
            body: formData,
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
              } else {
                console.log(response);
              }
            });
        }

        break;

      case 'forEmail':
        setSubActive2(true);
        roomesN?.map((inpItem, index) => {
          switch (inpItem.name) {
            case 'password2':
              inpItem.validation &&
                validationS(inpItem.type, inpItem.value, index, inpItem.name, passVal) === true &&
                (subActive = true);
              break;

            default:
              inpItem.validation &&
                validationS(inpItem.type, inpItem.value, index, inpItem.name) === true &&
                (subActive = true);
              break;
          }
        });
        if (!subActive) {
          roomesN?.map((inpItem, index) => {
            switch (inpItem.type) {
              case 'checkbox':
                formData.append(inpItem.nameForm, 1);
                break;
              case 'select':
                formData.append(inpItem.nameForm, inpItem.valId);
                break;
              default:
                formData.append(inpItem.nameForm, inpItem.value);
                break;
            }
          });
          // formData.append('recaptcha', 'test');
          fetch(API_URL + '/change-password-request', {
            method: 'POST',
            body: formData,
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
                runModalShow(4);
              } else {
                setErrTextApi(response?.errors);
                setSubActive2(false);
              }
            });
        } else {
          setSubActive2(false);
        }

        break;
      case 'activate':
        // subData = subData.split('confirm/');
        // const url = new URL(subData);

        if (subData && subData.get('confirm_user_id')) {
          formData.append('confirm_user_id', subData.get('confirm_user_id'));
          formData.append('need_agree', subData.get('need_agree'));
          formData.append('confirm_code', subData.get('confirm_code'));
          fetch(API_URL + '/registration/email-confirm', {
            method: 'POST',
            body: formData,
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
                setSubActive(true);
              } else {
                runModalShow(2);
                setErrTextApi(response.errors);
                setSubActive(true);
              }
            });
        }

        break;
        roomesH?.map(
          (inpItem, index) =>
            inpItem.validation &&
            validationH(inpItem.type, inpItem.value, index, inpItem.name) === true &&
            (subActive = true),
        );
        if (!subActive) {
          roomesH?.map((inpItem, index) => {
            switch (inpItem.type) {
              case 'checkbox':
                formData.append(inpItem.nameForm, 1);
                break;
              case 'select':
                formData.append(inpItem.nameForm, inpItem.valId);
                break;
              default:
                formData.append(inpItem.nameForm, inpItem.value);
                break;
            }
          });
          fetch(API_URL + '/attachLink', {
            method: 'POST',
            body: formData,
            headers: {
              'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
              'Access-Control-Allow-Origin': '*',
              'x-auth-token': localStorage.getItem('auth_key'),
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.result === true) {
                setRoomesH([formInput]);
                getProfile();
              } else {
                errValidH(response.error);
              }
            });
        }

        break;
      case 'close':
        fetch(API_URL + '/logout', {
          method: 'GET',
        })
          .then((response) => response.json())
          .then((response) => {
            if (response && response !== 'undefined' && response.status === true) {
              window.localStorage.clear();
              window.location.replace('/login');
            } else {
              console.log(response);
            }
          });
        break;
      default:
        break;
    }
  };

  const setValidationN = (error, is, errorText = '') => {
    let copy = [];
    copy = Object.assign([], roomesN);
    copy[is].err = error;
    copy[is].errorText = errorText;
    setRoomesN(copy);
  };
  const setValidationB = (error, is, errorText = '', nameBlock) => {
    let copy = [];
    copy = Object.assign([], roomesN);
    copy[nameBlock].err = error;
    copy[nameBlock].errorText = errorText;
    setRoomesN(copy);
  };
  const validationB = (type, value, id, name, passVal, nameBlock) => {
    let submit_enable = false;
    switch (type) {
      case 'email':
        if (value === '') {
          setValidationB(true, id, 'Поле обязательно для заполнения', nameBlock);
          submit_enable = true;
        } else {
          let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!re.test(value)) {
            setValidationN(true, id, 'E-mail не правильно написан');
          } else {
            setValidationB(false, id, '', nameBlock);
          }
        }
        break;
      case 'checkbox' && 'checkbox_simple':
        if (value === false) {
          setValidationB(true, id, 'Обязательно установить галочку', nameBlock);
          submit_enable = true;
        } else {
          setValidationB(false, id, '', nameBlock);
        }
        break;
      case 'select':
        if (value === '') {
          setValidationB(true, id);
          submit_enable = true;
        } else {
          setValidationB(false, id);
        }
        break;
      case 'password':
        if (name === 'password') {
          if (value === '') {
            setValidationB(true, id, 'Поле обязательно для заполнения', nameBlock);
            submit_enable = true;
          } else {
            setValidationB(false, id);
          }
        } else {
          if (value !== passVal) {
            setValidationB(true, id, 'Пароли не совпадают', nameBlock);
            submit_enable = true;
          } else {
            setValidationB(false, id);
          }
        }
        break;
      case 'phone':
        if (value === '' || value === null) {
          setValidationB(true, id, 'Поле обязательно для заполнения', nameBlock);
          submit_enable = true;
        } else {
          if (value.split('_')[0].length < 12) {
            setValidationB(true, id, 'Поле обязательно для заполнения', nameBlock);
            submit_enable = true;
          } else {
            setValidationB(false, id, 'Поле обязательно для заполнения', nameBlock);
          }
        }
        break;
      default:
        if (value === '' || value === null) {
          setValidationB(true, id, 'Поле обязательно для заполнения', nameBlock);
          submit_enable = true;
        } else {
          setValidationB(false, id, 'Поле обязательно для заполнения', nameBlock);
        }
        break;
    }
    return submit_enable;
  };
  const validationN = (type, value, id, name, passVal) => {
    let submit_enable = false;

    switch (type) {
      case 'email':
        if (value === '') {
          setValidationN(true, id, 'Поле обязательно для заполнения');
          submit_enable = true;
        } else {
          let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!re.test(value)) {
            setValidationN(true, id, 'E-mail не правильно написан');
          } else {
            setValidationN(false, id);
          }
        }
        break;
      case 'checkbox' && 'checkbox_simple':
        if (value === false) {
          setValidationN(true, id, 'Обязательно установить галочку');
          submit_enable = true;
        } else {
          setValidationN(false, id, '');
        }
        break;
      case 'select':
        if (value === '') {
          setValidationN(true, id);
          submit_enable = true;
        } else {
          setValidationN(false, id);
        }
        break;
      case 'password':
        if (name === 'password') {
          if (value === '') {
            setValidationN(true, id, 'Поле обязательно для заполнения');
            submit_enable = true;
          } else {
            setValidation(false, id);
          }
        } else {
          if (value !== passVal) {
            setValidationN(true, id, 'Пароли не совпадают');
            submit_enable = true;
          } else {
            setValidation(false, id);
          }
        }
        break;
      case 'phone':
      case 'personal_phone':
        if (value === '' || value === null) {
          setValidationN(true, id, 'Поле обязательно для заполнения');
          submit_enable = true;
        } else {
          if (value.split('_')[0].length < 12) {
            setValidationN(true, id, 'Поле обязательно для заполнения');
            submit_enable = true;
          } else {
            setValidationN(false, id, 'Поле обязательно для заполнения');
          }
        }
        break;
      default:
        if (value === '' || value === null) {
          setValidationN(true, id, 'Поле обязательно для заполнения');
          submit_enable = true;
        } else {
          setValidationN(false, id, 'Поле обязательно для заполнения');
        }
        break;
    }
    return submit_enable;
  };
  const handleroomN = (e, t, id, vId) => {
    setErrTextApi('');
    setSubActive(true);
    let copy = [];
    switch (t) {
      case 'select' && 'dropdown':
        setRoomesN((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e } : list)),
        );

        break;
      case 'checkbox':
        // setRoomesN([...roomesN, (roomesN[id].fields[vId].selected = e)]);

        copy = Object.assign([], roomesN);
        copy[id].fields[vId].selected = e;
        setRoomesN(copy);
        break;
      case 'recaptcha':
        // setRoomesN((room) =>
        //   room?.map((list, index) => (index === id ? { ...list, value: e } : list)),
        // );
        copy = Object.assign([], roomesN);
        copy[id].fields[vId].value = e;
        setRoomesN(copy);

        break;

      default:
        const { value } = e.target;
        copy = Object.assign([], roomesN);
        copy[id].fields[vId].value = value;
        setRoomesN(copy);
        break;
    }
  };
  const handleroomB = (e, t, id, vId, nameBlock) => {
    let copy = [];
    setSubActive(true);
    setErrTextApi('');
    switch (t) {
      case 'select' && 'dropdown':
        copy = Object.assign([], roomesN);
        copy[nameBlock].value = e;
        setRoomesN(copy);
        break;
      case 'checkbox':
        copy = Object.assign([], roomesN);
        copy[nameBlock].fields[vId].selected = e;
        setRoomesN(copy);
        break;
      case 'checkbox_simple':
        copy = Object.assign([], roomesN);
        copy[nameBlock].fields[vId].value = e;
        setRoomesN(copy);
        break;
      case 'recaptcha':
        setRoomesN((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e } : list)),
        );

        break;
      case 'address_dadata':
        copy = Object.assign([], roomesN);
        copy['address'].fields[0].value = e.value;
        copy['country'].fields[0].value = e.data.country === null ? '' : e.data.country;
        copy['country'].err = e.data.country !== null ? false : true;
        copy['zip'].fields[0].value = e.data.postal_code === null ? '' : e.data.postal_code;
        copy['zip'].err = e.data.postal_code !== null ? false : true;
        copy['city'].fields[0].value =
          (e.data.city_with_type !== null ? e.data.city_with_type : '') +
          ' ' +
          (e.data.settlement_with_type != null ? e.data.settlement_with_type : '');
        copy['city'].err =
          e.data.city_with_type !== null || e.data.settlement_with_type !== null ? false : true;
        copy['region'].fields[0].value =
          e.data.region_with_type === null ? '' : e.data.region_with_type;
        copy['region'].err = e.data.region_with_type !== null ? false : true;
        copy['street'].fields[0].value =
          e.data.street_with_type === null ? '' : e.data.street_with_type;
        copy['house'].fields[0].value = e.data.house === null ? '' : e.data.house;
        copy['house'].err = e.data.house !== null ? false : true;
        copy['building'].fields[0].value = e.data.block === null ? '' : e.data.block;
        copy['flat'].fields[0].value = e.data.flat === null ? '' : e.data.flat;
        setRoomesN(copy);
        break;
      default:
        const { value } = e.target;
        copy = Object.assign([], roomesN);
        copy[nameBlock].fields[vId].value = value;
        setRoomesN(copy);
        break;
    }
  };
  //
  const modalClose = (nameClose) => {
    setErrTextApi('');
    switch (nameClose) {
      case 'terms':
        setModalShow(2);
        break;

      default:
        setModalShow(null);
        document.body.classList.remove('overflow-disabled');
        break;
    }
  };
  const handleroom = (e, t, id, vId) => {
    setErrTextApi('');
    setSubActive(true);
    setSubActive2(true);
    switch (t) {
      case 'select':
        setRoomes((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e, valId: vId } : list)),
        );

        break;
      case 'checkbox':
        setRoomes((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e } : list)),
        );

        break;
      case 'recaptcha':
        setRoomes((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e } : list)),
        );

        break;

      default:
        const { value } = e.target;
        setRoomes((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: value } : list)),
        );
        break;
    }
  };
  const handleroomS = (e, t, id, vId) => {
    setSubActive(true);
    setSubActive2(true);
    setErrTextApi('');
    switch (t) {
      case 'select':
        setRoomesN((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e, valId: vId } : list)),
        );

        break;
      case 'checkbox':
        setRoomesN((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e } : list)),
        );

        break;
      case 'recaptcha':
        setRoomesN((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e } : list)),
        );

        break;

      default:
        const { value } = e.target;
        setRoomesN((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: value } : list)),
        );
        break;
    }
  };

  // form page
  const setValidationH = (error, is) => {
    setRoomesH((room) =>
      room?.map((list, index) => (index === is ? { ...list, err: error } : list)),
    );
  };
  const validationH = (type, value, id, name, passVal) => {
    let submit_enable = false;
    switch (type) {
      case 'email':
        if (value === '') {
          setValidationH(true, id);
          submit_enable = true;
        } else {
          let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!re.test(value)) {
            setValidationH(true, id);
          } else {
            setValidationH(false, id);
          }
        }
        break;
      case 'checkbox':
        if (value === false) {
          setValidationH(true, id);
          submit_enable = true;
        } else {
          setValidationH(false, id);
        }
        break;
      case 'select':
        if (value === '') {
          setValidationH(true, id);
          submit_enable = true;
        } else {
          setValidationH(false, id);
        }
        break;
      case 'password':
        if (name === 'password') {
          if (value === '') {
            setValidationH(true, id);
            submit_enable = true;
          } else {
            setValidationH(false, id);
          }
        } else {
          if (value !== passVal) {
            setValidationH(true, id);
            submit_enable = true;
          } else {
            setValidationH(false, id);
          }
        }
        break;
      default:
        if (value === '') {
          setValidationH(true, id);
          submit_enable = true;
        } else {
          setValidationH(false, id);
        }
        break;
    }
    return submit_enable;
  };
  const errValidH = (res) => {
    if (res.text !== undefined) {
      setRoomesH((room) =>
        room?.map((list, index) =>
          list.name === 'text' ? { ...list, errorText: res.text[0], err: true } : list,
        ),
      );
    }
    if (res.url !== undefined) {
      setRoomesH((room) =>
        room?.map((list, index) =>
          list.name === 'shareURL' ? { ...list, errorText: res.url[0], err: true } : list,
        ),
      );
    }
  };
  const handleroomH = (e, t, id, vId) => {
    setSubActive(true);
    setErrTextApi('');
    switch (t) {
      case 'select':
        setRoomesH((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e, valId: vId } : list)),
        );

        break;
      case 'checkbox':
        setRoomesH((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e } : list)),
        );

        break;
      case 'recaptcha':
        setRoomesH((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: e } : list)),
        );

        break;

      default:
        const { value } = e.target;
        setRoomesH((room) =>
          room?.map((list, index) => (index === id ? { ...list, value: value } : list)),
        );
        break;
    }
  };
  const runModalShow = (id, mass) => {
    setModalShow(id);
    document.body.classList.add('overflow-disabled');
    // mass && setRoomes([...formInput2[mass]]);
    // mass === 'newAddress' && submitN(mass);
  };
  const errValid = (res) => {
    if (res.name !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'name' ? { ...list, errorText: res.name[0], err: true } : list,
        ),
      );
    }
    if (res.text !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'text' ? { ...list, errorText: res.text[0], err: true } : list,
        ),
      );
    }
    if (res.password !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'password' ? { ...list, errorText: res.password[0], err: true } : list,
        ),
      );
    }
    if (res.accept1 !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'accept1' ? { ...list, errorText: res.accept1[0], err: true } : list,
        ),
      );
    }
    if (res.email !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'email' ? { ...list, errorText: res.email[0], err: true } : list,
        ),
      );
    }
    if (res.login !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'email' ? { ...list, errorText: res.login[0], err: true } : list,
        ),
      );
    }
    if (res.subject_id !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'subject_id' ? { ...list, errorText: res.subject_id[0], err: true } : list,
        ),
      );
    }
    if (res.region_id !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'region_id' ? { ...list, errorText: res.region_id[0], err: true } : list,
        ),
      );
    }
    if (res.recaptcha !== undefined) {
      setRoomes((room) =>
        room?.map((list, index) =>
          list.name === 'recaptcha' ? { ...list, errorText: res.recaptcha[0], err: true } : list,
        ),
      );
    }
  };
  const togglePass = (is, type) => {
    setRoomes((room) =>
      room?.map((list, index) =>
        index === is
          ? {
              ...list,
              type: type === 'password' ? 'text' : 'password',
              toggleActive: !list.toggleActive,
            }
          : list,
      ),
    );
  };
  const submit = (formName, subData, subData2) => {
    let subActive = false;
    const formData = new FormData();
    let names = [];
    let passId;
    let passVal;
    switch (formName) {
      case 'feedack':
        roomes?.map(
          (inpItem, index) =>
            inpItem.validation &&
            validation(inpItem.type, inpItem.value, index) === true &&
            (subActive = true),
        );
        if (!subActive) {
          roomes?.map((inpItem, index) => {
            switch (inpItem.type) {
              case 'checkbox':
                formData.append(inpItem.name, 1);
                break;
              case 'select':
                formData.append(inpItem.name, inpItem.valId);
                break;
              default:
                formData.append(inpItem.nameForm, inpItem.value);
                break;
            }
          });
          fetch(API_URL + '/sendFeedback', {
            method: 'POST',
            body: formData,
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.result === true) {
                setModalShow(3);
              } else {
                errValid(response.error);
              }
            });
        }

        break;
      case 'registr':
        names = roomes.map((el) => el.name);
        passId = names.indexOf('password');
        passVal = roomes[passId].value;
        roomes?.map((inpItem, index) => {
          if (inpItem.typeMain === 'password' && inpItem.type === 'text') {
            roomes[index].type = 'password';
            roomes[index].toggleActive = !roomes[index].toggleActive;
          }
          switch (inpItem.name) {
            case 'password2':
              inpItem.validation &&
                validation(inpItem.type, inpItem.value, index, inpItem.name, passVal) === true &&
                (subActive = true);
              break;

            default:
              inpItem.validation &&
                validation(inpItem.type, inpItem.value, index, inpItem.name) === true &&
                (subActive = true);
              break;
          }
        });
        if (!subActive) {
          roomes?.map((inpItem, index) => {
            switch (inpItem.type) {
              case 'checkbox':
                formData.append(inpItem.nameForm, 1);
                break;
              case 'select':
                formData.append(inpItem.nameForm, inpItem.valId);
                break;
              default:
                formData.append(inpItem.nameForm, inpItem.value);
                break;
            }
          });
          formData.append('recaptcha', 'test');
          fetch(API_URL + '/signup', {
            method: 'POST',
            body: formData,
            headers: {
              'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
              'Access-Control-Allow-Origin': '*',
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.result === true) {
                runModalShow(9);
              } else {
                errValid(response.error);
              }
            });
        }

        break;
      case 'login':
        roomes?.map(
          (inpItem, index) =>
            inpItem.validation &&
            validation(inpItem.type, inpItem.value, index, inpItem.name) === true &&
            (subActive = true),
        );
        if (!subActive) {
          roomes?.map((inpItem, index) => {
            switch (inpItem.type) {
              case 'checkbox':
                formData.append(inpItem.nameForm, 1);
                break;
              case 'select':
                formData.append(inpItem.nameForm, inpItem.valId);
                break;
              default:
                formData.append(inpItem.nameForm, inpItem.value);
                break;
            }
          });
          fetch(API_URL + '/login', {
            method: 'POST',
            body: formData,
            headers: {
              'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
              'Access-Control-Allow-Origin': '*',
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.result === true) {
                localStorage.setItem('auth_key', response.data.auth_key);
                localStorage.setItem('isUploaded', response.data.avatar.isUploaded);
                localStorage.setItem('isChosen', response.data.avatar.isChosen);
                localStorage.setItem('profile', JSON.stringify(response.data));
                localStorage.setItem('avatar', JSON.stringify(response.data.avatar.options));
                !response.data.avatar.isChosen
                  ? window.location.replace('/upload-photo')
                  : window.location.replace('/profile');
              } else {
                errValid(response.error);
              }
            });
        }

        break;
      case 'forgonLogin':
        names = roomes.map((el) => el.name);
        passId = names.indexOf('password');
        passVal = roomes[passId].value;
        roomes?.map((inpItem, index) => {
          if (inpItem.typeMain === 'password' && inpItem.type === 'text') {
            roomes[index].type = 'password';
            roomes[index].toggleActive = !roomes[index].toggleActive;
          }
          switch (inpItem.name) {
            case 'password2':
              inpItem.validation &&
                validation(inpItem.type, inpItem.value, index, inpItem.name, passVal) === true &&
                (subActive = true);
              break;

            default:
              inpItem.validation &&
                validation(inpItem.type, inpItem.value, index, inpItem.name) === true &&
                (subActive = true);
              break;
          }
        });
        if (!subActive) {
          roomes?.map((inpItem, index) => {
            switch (inpItem.type) {
              case 'checkbox':
                formData.append(inpItem.nameForm, 1);
                break;
              case 'select':
                formData.append(inpItem.nameForm, inpItem.valId);
                break;
              default:
                formData.append(inpItem.nameForm, inpItem.value);
                break;
            }
          });
          formData.append('recaptcha', 'test');
          fetch(API_URL + '/change/password', {
            method: 'POST',
            body: formData,
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.result === true) {
                runModalShow(5);
              } else {
                errValid(response.error);
              }
            });
        }

        break;
      case 'forEmail':
        roomesN?.map((inpItem, index) => {
          switch (inpItem.name) {
            case 'password2':
              inpItem.validation &&
                validationS(inpItem.type, inpItem.value, index, inpItem.name, passVal) === true &&
                (subActive = true);
              break;

            default:
              inpItem.validation &&
                validationS(inpItem.type, inpItem.value, index, inpItem.name) === true &&
                (subActive = true);
              break;
          }
        });
        if (!subActive) {
          roomes?.map((inpItem, index) => {
            switch (inpItem.type) {
              case 'checkbox':
                formData.append(inpItem.nameForm, 1);
                break;
              case 'select':
                formData.append(inpItem.nameForm, inpItem.valId);
                break;
              default:
                formData.append(inpItem.nameForm, inpItem.value);
                break;
            }
          });
          fetch(API_URL + '/change-password-request', {
            method: 'POST',
            body: formData,
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.result === true) {
              } else {
                errValid(response.error);
              }
            });
        }

        break;
      case 'uploadAvatar':
        if (!subActive) {
          formData.append('photo', subData);
          fetch(API_URL + '/uploadAvatar', {
            method: 'POST',
            body: formData,
            headers: {
              'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
              'Access-Control-Allow-Origin': '*',
              'x-auth-token': localStorage.getItem('auth_key'),
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.result === true) {
                localStorage.setItem('name', response.data.name);
                localStorage.setItem('email', response.data.login);
                localStorage.setItem('isChosen', response.data.avatar.isChosen);
                localStorage.setItem('isUploaded', response.data.avatar.isUploaded);
                localStorage.setItem('profile', JSON.stringify(response.data));
                response.data.avatar.options !== null &&
                  window.localStorage.setItem(
                    'avatar',
                    JSON.stringify(response.data.avatar.options),
                  );
                response.data.avatar.options !== null && setWaitingTime(0);
                setProfile(JSON.parse(localStorage.getItem('profile')));
                setAvatar(JSON.parse(localStorage.getItem('avatar')));
                response.data.waitingTime && setWaitingTime(response.data.waitingTime);
                response.data.waitingTime &&
                  localStorage.setItem('waitingTime', response.data.waitingTime);
                response.data.waitingTime && localStorage.setItem('waitingTimeS', 0);
              } else {
                getProfile();
              }
            });
        }

        break;
      case 'chooseAvatar':
        if (!subActive) {
          formData.append('id', subData);
          fetch(API_URL + '/chooseAvatar', {
            method: 'POST',
            body: formData,
            headers: {
              'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
              'Access-Control-Allow-Origin': '*',
              'x-auth-token': localStorage.getItem('auth_key'),
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.result === true) {
                getProfile();
                setTimeout(() => {
                  window.location.replace('/profile');
                }, 300);
              } else {
                getProfile();
              }
            });
        }

        break;
      case 'uploadProduct':
        if (!subActive) {
          setErrProduct('send');
          formData.append('photo', subData);
          fetch(API_URL + '/uploadProduct', {
            method: 'POST',
            body: formData,
            headers: {
              'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
              'Access-Control-Allow-Origin': '*',
              'x-auth-token': localStorage.getItem('auth_key'),
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.result === true) {
                document.getElementById('upload2').scrollIntoView();
                getProfile();
                setErrProduct('');
                getProfile();
                setTimeout(() => {
                  getProfile();
                }, 300);
              } else {
                setErrProduct(response.error.photo);
              }
            });
        }

        break;
      case 'activate':
        subData = subData.split('confirm/');
        if (!subActive) {
          formData.append('token', subData[1]);
          fetch(API_URL + '/activate', {
            method: 'POST',
            body: formData,
            headers: {
              'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
              'Access-Control-Allow-Origin': '*',
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.result === true) {
                localStorage.setItem('auth_key', response.data.auth_key);
                localStorage.setItem('isChosen', response.data.avatar.isChosen);
                localStorage.setItem('isUploaded', response.data.avatar.isUploaded);
                localStorage.setItem('profile', JSON.stringify(response.data));
                localStorage.setItem('avatar', JSON.stringify(response.data.avatar.options));
                window.location.replace('/#steps');
              } else {
                window.location.replace('/');
                console.log(response.error);
              }
            });
        }

        break;
      case 'share':
        if (!subActive) {
          formData.append('service', subData);
          formData.append('image', subData2);
          fetch(API_URL + '/share', {
            method: 'POST',
            body: formData,
            headers: {
              'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
              'Access-Control-Allow-Origin': '*',
              'x-auth-token': localStorage.getItem('auth_key'),
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.result === true) {
                let height = 600;
                let width = 500;
                var top = (window.screen.height - height) / 2;
                var left = 0;
                localStorage.setItem('avaLink_' + subData, response.data.link);
              } else {
              }
            });
        }

        break;
      case 'shareLink':
        roomesH?.map(
          (inpItem, index) =>
            inpItem.validation &&
            validationH(inpItem.type, inpItem.value, index, inpItem.name) === true &&
            (subActive = true),
        );
        if (!subActive) {
          roomesH?.map((inpItem, index) => {
            switch (inpItem.type) {
              case 'checkbox':
                formData.append(inpItem.nameForm, 1);
                break;
              case 'select':
                formData.append(inpItem.nameForm, inpItem.valId);
                break;
              default:
                formData.append(inpItem.nameForm, inpItem.value);
                break;
            }
          });
          fetch(API_URL + '/attachLink', {
            method: 'POST',
            body: formData,
            headers: {
              'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
              'Access-Control-Allow-Origin': '*',
              'x-auth-token': localStorage.getItem('auth_key'),
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.result === true) {
                setRoomesH([formInput]);
                getProfile();
              } else {
                errValidH(response.error);
              }
            });
        }

        break;
      case 'close':
        window.localStorage.clear();
        window.location.replace('/');

        break;
      default:
        console.log('sd');
        break;
    }
  };
  const clickMenu = () => {
    setNav(!nav);
    document.body.classList.toggle('openMenu');
  };
  const clickMenuClose = () => {
    setNav(false);
    document.body.classList.remove('openMenu');
  };
  const getMenu = () => {
    fetch(API_URL + '/menu/personal', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response !== 'undefined' && response.status === true) {
          setMenu(response.data);
        } else {
          console.log(response);
        }
      });
  };
  const getProfile = () => {
    var myHeaders = new Headers();
    myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
    fetch(API_URL + '/profile', {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response !== 'undefined' && response.status === true) {
          setProfile([response.data]);
        }

        if (response.status === 401) {
          response.status === 401 && window.location.replace('/');
          window.localStorage.clear();
        }
      });
  };

  return (
    <div
      onClick={() => {
        setMenuItemsActive(null);
        setMenuFooterItemsActive(null);
      }}>
      <Header
        menuTop={menuTop}
        submit={submitN}
        menuItemsActive={menuItemsActive}
        setMenuItemsActive={setMenuItemsActive}
        clickMenu={clickMenu}
      />
      <Switch>
        <Route
          key="main"
          exact
          path="/"
          render={(props) => (
            <Login
              nameForm="login"
              formInput2={formInput2}
              roomes={roomes}
              roomesN={roomesN}
              setRoomes={setRoomes}
              setRoomesN={setRoomesN}
              setValidation={setValidation}
              togglePass={togglePass}
              handleroom={handleroom}
              errTextApi={errTextApi}
              setErrTextApi={setErrTextApi}
              submit={submitN}
              handleroomS={handleroomS}
              subActive={subActive}
              subActive2={subActive2}
              setSubActive2={setSubActive2}
              menu={menu}
              modalShow={modalShow}
              runModalShow={runModalShow}
              modalClose={modalClose}
              setStatement={setStatement}
              modals={Modals}
              {...props}
            />
          )}
        />
        <Route
          key="main"
          exact
          path="/home"
          render={(props) => (
            <Home
              API_URL={API_URL}
              profile={profile}
              {...props}
              menu={menu}
              submit={submitN}
              setStatement={setStatement}
              setErrTextApi={setErrTextApi}
              errTextApi={errTextApi}
              // возврат средств
              statement={statement}
              formInput2={formInput2}
              setRoomesN={setRoomesN}
              roomesS={roomes}
              setRoomesS={setRoomes}
              roomesH={roomesH}
              setRoomesH={setRoomesH}
              setValidationH={setValidationH}
              handleroomH={handleroomH}
              setValidationS={setValidation}
              togglePassS={togglePass}
              handleroomS={handleroom}
              setAddressList={setAddressList}
              addressList={addresslist}
              setBlockN={setBlockN}
              blockN={blockN}
              subActive={subActive}
              roomesN={roomesN}
              setSubActive={setSubActive}
              handleroom={handleroomB}
              setValidation={setValidationB}
              // modal
              modalShow={modalShow}
              runModalShow={runModalShow}
              modalClose={modalClose}
              modals={Modals}
            />
          )}
        />
        <Route
          key="Order"
          exact
          path="/order"
          render={(props) => (
            <Order
              API_URL={API_URL}
              profile={profile}
              {...props}
              menu={menu}
              submit={submitN}
              setStatement={setStatement}
              setErrTextApi={setErrTextApi}
            />
          )}
        />
        <Route
          key="Return"
          exact
          path="/return"
          render={(props) => (
            <Return
              API_URL={API_URL}
              profile={profile}
              {...props}
              setAddressList={setAddressList}
              addressList={addresslist}
              formInput2={formInput2}
              setRoomesN={setRoomesN}
              setValidation={setValidationB}
              togglePass={togglePassN}
              handleroom={handleroomB}
              subActive={subActive}
              errTextApi={errTextApi}
              statement={statement}
              setStatement={setStatement}
              blockN={blockN}
              setBlockN={setBlockN}
              roomesN={roomesN}
              menu={menu}
              submit={submitN}
              modalShow={modalShow}
              runModalShow={runModalShow}
              modalClose={modalClose}
              modals={Modals}
              setErrTextApi={setErrTextApi}
              setSubActive={setSubActive}
              // возврат средств
              roomesS={roomes}
              setRoomesS={setRoomes}
              roomesH={roomesH}
              setRoomesH={setRoomesH}
              setValidationH={setValidationH}
              handleroomH={handleroomH}
              setValidationS={setValidation}
              togglePassS={togglePass}
              handleroomS={handleroom}
            />
          )}
        />
        <Route
          key="Address"
          exact
          path="/address"
          render={(props) => (
            <Address
              API_URL={API_URL}
              profile={profile}
              {...props}
              setAddressList={setAddressList}
              addressList={addresslist}
              formInput2={formInput2}
              setRoomesN={setRoomesN}
              setValidation={setValidationB}
              togglePass={togglePassN}
              handleroom={handleroomB}
              submit={submitN}
              subActive={subActive}
              errTextApi={errTextApi}
              blockN={blockN}
              setBlockN={setBlockN}
              roomesN={roomesN}
              menu={menu}
              modalShow={modalShow}
              runModalShow={runModalShow}
              modalClose={modalClose}
              modals={Modals}
              setStatement={setStatement}
              setErrTextApi={setErrTextApi}
            />
          )}
        />
        <Route
          key="Address"
          exact
          path="/address2"
          render={(props) => (
            <Address2
              API_URL={API_URL}
              profile={profile}
              {...props}
              setAddressList={setAddressList}
              addressList={addresslist}
              formInput2={formInput2}
              setRoomesN={setRoomesN}
              setValidation={setValidationB}
              togglePass={togglePassN}
              handleroom={handleroomB}
              submit={submitN}
              subActive={subActive}
              errTextApi={errTextApi}
              blockN={blockN}
              setBlockN={setBlockN}
              roomesN={roomesN}
              menu={menu}
              modalShow={modalShow}
              runModalShow={runModalShow}
              modalClose={modalClose}
              setStatement={setStatement}
              modals={Modals}
              setErrTextApi={setErrTextApi}
            />
          )}
        />
        <Route
          key="Personal"
          exact
          path="/personal"
          render={(props) => (
            <Personal
              profile={profile}
              {...props}
              menu={menu}
              nameForm="registr"
              formInput2={formInput2}
              roomesN={roomesN}
              roomes={roomes}
              setRoomesN={setRoomesN}
              setRoomes={setRoomes}
              setValidationB={setValidationB}
              setValidation={setValidationN}
              togglePass={togglePassN}
              togglePassS={togglePass}
              handleroom={handleroomB}
              submit={submitN}
              subActive={subActive}
              subActive2={subActive2}
              errTextApi={errTextApi}
              setStatement={setStatement}
              API_URL={API_URL}
              handleroomS={handleroom}
              setErrTextApi={setErrTextApi}
              setBlockN={setBlockN}
              blockN={blockN}
              {...props}
            />
          )}
        />
        <Route
          key="AddAddress"
          exact
          path="/addaddress"
          render={(props) => (
            <AddAddress
              API_URL={API_URL}
              profile={profile}
              {...props}
              menu={menu}
              blockN={blockN}
              setBlockN={setBlockN}
              nameForm="registr"
              formInput2={formInput2}
              roomesN={roomesN}
              setRoomesN={setRoomesN}
              setValidation={setValidationB}
              togglePass={togglePassN}
              handleroom={handleroomB}
              submit={submitN}
              setStatement={setStatement}
              setErrTextApi={setErrTextApi}
              subActive={subActive}
              errTextApi={errTextApi}
            />
          )}
        />
        <Route
          key="EndRegistration"
          exact
          path="/end-registration"
          render={(props) => <EndRegistration />}
        />
        <Route
          key="Registration"
          exact
          path="/registration"
          render={(props) => (
            <Registration
              nameForm="registr"
              formInput2={formInput2}
              roomesN={roomesN}
              setRoomesN={setRoomesN}
              setValidation={setValidationN}
              togglePass={togglePassN}
              handleroom={handleroomN}
              submit={submitN}
              subActive={subActive}
              errTextApi={errTextApi}
              API_URL={API_URL}
              modalShow={modalShow}
              runModalShow={runModalShow}
              modalClose={modalClose}
              setStatement={setStatement}
              setErrTextApi={setErrTextApi}
              modals={Modals}
              {...props}
            />
          )}
        />
        <Route
          key="Login"
          exact
          path="/login"
          render={(props) => (
            <Login
              nameForm="login"
              formInput2={formInput2}
              roomes={roomes}
              roomesN={roomesN}
              setRoomes={setRoomes}
              setRoomesN={setRoomesN}
              setValidation={setValidation}
              togglePass={togglePass}
              handleroom={handleroom}
              errTextApi={errTextApi}
              setErrTextApi={setErrTextApi}
              submit={submitN}
              handleroomS={handleroomS}
              subActive={subActive}
              subActive2={subActive2}
              setSubActive2={setSubActive2}
              menu={menu}
              modalShow={modalShow}
              runModalShow={runModalShow}
              modalClose={modalClose}
              setStatement={setStatement}
              modals={Modals}
              {...props}
            />
          )}
        />
        <Route
          key="ForgonLogin"
          exact
          path="/auth/recovery"
          render={(props) => (
            <ForgonLogin
              nameForm="forgonLogin"
              formInput2={formInput2}
              roomes={roomes}
              setRoomes={setRoomes}
              setValidation={setValidation}
              togglePass={togglePass}
              handleroom={handleroom}
              errTextApi={errTextApi}
              submit={submitN}
              setStatement={setStatement}
              subActive={subActive}
              menu={menu}
              {...props}
            />
          )}
        />
        <Route
          key="confirm_registration"
          exact
          path="/auth/confirm_registration"
          render={(props) => (
            <Confirm
              nameForm="registr"
              formInput2={formInput2}
              roomes={roomes}
              roomesN={roomesN}
              setRoomes={setRoomes}
              setRoomesN={setRoomesN}
              setValidation={setValidationN}
              togglePass={togglePassN}
              handleroom={handleroomN}
              submit={submitN}
              subActive={subActive}
              errTextApi={errTextApi}
              API_URL={API_URL}
              modalShow={modalShow}
              runModalShow={runModalShow}
              modalClose={modalClose}
              modals={Modals}
              {...props}
            />
          )}
        />
        <Route
          key="password-new"
          exact
          path="/password-new"
          render={(props) => <PasswordNew {...props} />}
        />
        <Route
          path="*"
          render={(props) => (
            <Home
              API_URL={API_URL}
              profile={profile}
              {...props}
              menu={menu}
              submit={submitN}
              setStatement={setStatement}
              setErrTextApi={setErrTextApi}
              errTextApi={errTextApi}
              // возврат средств
              statement={statement}
              formInput2={formInput2}
              setRoomesN={setRoomesN}
              roomesS={roomes}
              setRoomesS={setRoomes}
              roomesH={roomesH}
              setRoomesH={setRoomesH}
              setValidationH={setValidationH}
              handleroomH={handleroomH}
              setValidationS={setValidation}
              togglePassS={togglePass}
              handleroomS={handleroom}
              setAddressList={setAddressList}
              addressList={addresslist}
              setBlockN={setBlockN}
              blockN={blockN}
              roomesN={roomesN}
              // modal
              modalShow={modalShow}
              runModalShow={runModalShow}
              modalClose={modalClose}
              modals={Modals}
            />
          )}
        />
      </Switch>

      <Footer
        menuFooterItemsActive={menuFooterItemsActive}
        setMenuFooterItemsActive={setMenuFooterItemsActive}
      />
      <div onClick={() => runModalShow(6)} id="agree" className="d-hidden m-hidden"></div>
    </div>
  );
}
