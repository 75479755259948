import React from 'react';
export default function ModalView({ children, ...props }) {
  return (
    <div
      data-show={props.modalShow === props.modalData.id ? 'true' : 'false'}
      className={
        'modal__custom modal__custom-big modal__custom-reg modal__custom-' + props.modalData.id
      }
      key={'modal_' + props.modalData.id}>
      <div className={'modal__box ' + props.class}>
        <div
          className="modal__close d-flex align-items-center justify-content-center"
          onClick={() => {
            localStorage.getItem('editAddressId') && localStorage.removeItem('editAddressId');
            props.nameClose ? props.modalClose(props.nameClose) : props.modalClose(null);
          }}></div>
        <div className="modal__box-content">
          {props.modalData.name && (
            <div className="modal__box-header d-flex align-items-end justify-content-between">
              <div
                className="form-title "
                dangerouslySetInnerHTML={{ __html: props.modalData.name }}></div>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
