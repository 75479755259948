import React, { Component } from 'react';
require('es6-promise').polyfill();
require('isomorphic-fetch');
export default class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}
  render() {
    return (
      <div className="bann">
        <div className="bann__img"></div>
        <div className="bann__text">
          <div className="bann__text-title">
            Начните бесплатное онлайн-обучение в&nbsp;Школе Хороших Сахаров!
          </div>
          <div className="bann__text-box m-f-column">
            <div className="bann__text-box-left">
              <p>
                Присоединяйтесь к&nbsp;бесплатному образовательному проекту &laquo;Школа Хороших Сахаров&raquo;! Вы&nbsp;узнаете, как управлять диабетом в&nbsp;различных сферах жизни и&nbsp;поддерживать целевой уровень глюкозы при помощи системы FreeStyle Libre.
              </p>
            </div>
            <div className="bann__text-box-right">
              <a href="https://school.freestylediabetes.ru/" className="btn btn-blue">
                выбрать курс
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
