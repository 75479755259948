import React, { useState, useEffect } from 'react';
import SideNav from './SideNav';
import Input from './InputBlock';
import OrderList from './OrderList';
import Paginate from './Paginate';

export default function App(props) {
  const [dataF, setDataF] = useState([]);
  const [focus, setFocus] = useState(null);
  const [errM, setErrM] = useState([]);
  const [error2, setError2] = useState([]);
  const [topicDropdown, setTopicDropdown] = useState(null);
  const [obj, setObj] = useState([{ err: false }]);
  const [select, setSelect] = useState(false);
  useEffect(() => {
    document.body.classList.add('personal');
    form();
  }, []);
  // useEffect(() => {
  //   // Object.entries(dataF).map(([key, value]) => console.log(value));
  //   setDataF((dataF) => Object.entries(dataF).map(([key, value]) => (value['err'] = false)));
  //   console.log(dataF);
  // }, [dataF]);
  const renderError = (errText, err) => {
    if (err) {
      return <div className="error__message">{errText}</div>;
    }
  };
  const btnTopicDropdown = (id) => {
    id === topicDropdown ? setTopicDropdown(null) : setTopicDropdown(id);
  };
  const form = () => {
    var myHeaders = new Headers();
    myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
    fetch(props.API_URL + '/address/fields', {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response && response.status === true) {
          setDataF(response.data.fields);
          // props.setBlockN([...response.data.blocks[0].block.rows]);
          // let copy = [];
          // copy = Object.assign([], roomesN);
          // console.log(copy);
          // copy[index].fields[0].type =
          //   copy[index].fields[0].type !== 'password' ? 'password' : 'password';
          // setRoomesN(copy);
          setDataF((roomsN) =>
            Object.entries(roomsN).map(
              ([key, value]) => (
                (value['err'] = false),
                (value['value'] = key === 'name_type' ? 'Господин' : ''),
                (value['errorText'] = '')
              ),
            ),
          );

          // тестовый вариант с добавлением

          // props.setRoomesN(new Object(dataF));
          props.setRoomesN(new Object(response.data.fields));
          props.roomesN !== [] && console.log(props.roomesN);
          // console.log(response);
          // console.log(props.roomesN);
          // setDataF([...response.data.fields]);
          props.setBlockN([...response.data.blocks[0].block.rows]);
          // let copy = [];
          // copy = Object.assign([], roomesN);
          // console.log(copy);
          // copy[index].fields[0].type =
          //   copy[index].fields[0].type !== 'password' ? 'password' : 'password';
          // setRoomesN(copy);
          // props.setRoomesN((roomsN) =>
          //   Object.entries(roomsN).map(
          //     ([key, value]) => (
          //       (value['err'] = false), (value['value'] = ''), (value['errorText'] = '')
          //     ),
          //   ),
          // );
          // props.setRoomesN((roomsN) =>
          //   Object.entries(roomsN).map(([key, value]) => console.log(value)),
          // );
          // props.roomesN.map(([key, value]) => console.log(value));
          console.log(props.roomesN);
          // console.log(Object.entries(props.roomesN));
          // props.setRoomesN((roomsN) => console.log(roomsN));
          // props.setRoomesN((roomesN) =>
          //   roomesN?.map((list, index) =>
          //     index === index
          //       ? {
          //           ...list,
          //           ...(list['Err'] = false),
          //           ...(list['err'] = false),
          //           ...(list['value'] = ''),
          //           ...(list['errorText'] = ''),
          //         }
          //       : list,
          //   ),
          // );
          // props.setRoomesN((room) =>
          //   room?.map((list, index) =>
          //     index === index ? { ...list, value: list.fields[0].value } : list,
          //   ),
          // );
        }
      });
  };

  return (
    <div className="content d-flex m-f-column">
      <SideNav menu={props.menu} profile={props.profile} />
      <div className="content__right">
        <div className="content__page">
          <div className="form text-center d-flex d-aling-center d-f-column">
            <div className="form-title ">Ваши персональные данные</div>
            {/* {props.roomesN['city']?.['title']} */}
            {/* {props.roomesN.map(function (item) {
            })} */}
            {/* {dataF.find('')} */}

            {/* <Input rooms={JSON.parse(data)} /> */}
            {/* {props.errTextApi !== '' && (
              <div
                className="form__item form__err"
                dangerouslySetInnerHTML={{ __html: props.errTextApi }}></div>
            )} */}
            {/* рабочий вариант */}
            {/* {dataF?.['city']?.title} */}

            {/* {props.blockN.map((blocksData, i) => {
              return (
                <div>
                  {blocksData.cols.map((blockData, i) => {
                    return (
                      blockData.key !== null && (
                        <div
                          className="d-flex text-center d-f-column"
                          key={'roomData.nameForm' + '_' + i}>
                          {dataF[blockData.key]?.fields?.map((inpData, inpI) => {
                            // return inpData?.name;
                            switch (inpData.type) {
                              case 'qawsas':
                                break;

                              default:
                                return <div>{inpData.type}</div>;
                                break;
                            }
                          })}
                        </div>
                      )
                    );
                  })}
                </div>
              );
            })} */}
            <Input
              blockN={props.blockN}
              rooms={props.roomesN}
              setRooms={props.setRoomesN}
              setValidation={props.setValidation}
              togglePass={props.togglePass}
              handleroom={props.handleroom}
            />
            <button
              onClick={() => {
                props.submit('addressAdd');
                // console.log(props.roomesN);
              }}
              className={'btn' + (!props.subActive ? ' btn-grey' : ' btn-blue2')}>
              Создать учетную запись
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
