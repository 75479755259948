import React, { Component } from 'react';
import logo from '../assets/img/logo.png';
require('es6-promise').polyfill();
require('isomorphic-fetch');
export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: false,
      BodyClass: '',
      tab: 0,
      cabinet: false,
    };
  }

  componentDidMount() {}
  render() {
    return (
      <div
        className={
          'header header_min ' +
          (!this.props.directionDown && this.props.scroll > 5 ? 'top ' : '') +
          (this.props.nav === true ? 'active' : '')
        }>
        <div className="header__1">
          <div className="container d-flex flex-row">
            <div className="logo__box d-flex align-items-center">
              <a href="https://freestylediabetes.ru/" className="logo">
                <img src={logo} className="nav-logo" alt="Logo" />
              </a>
            </div>
            <div className={'header__right m-hidden'}>
              <div
                className="btn btn-close-menu d-hidden"
                onClick={() => {
                  this.props.clickMenu();
                }}></div>
              <ul id="menu">
                {this.props.menuTop?.map((mn, i) => (
                  <li key={mn.link + '_' + i}>
                    <a href={mn.url}>{mn.name}</a>
                    {mn.items && (
                      <span
                        className={
                          'ico ico-arrow-blue ' +
                          (this.props.menuItemsActive === i && 'ico-arrow-blue-active')
                        }
                        onClick={() => {
                          // this.setState({
                          //   menuItemsActive: this.state.menuItemsActive !== i ? i : null,
                          // });
                          setTimeout(() => {
                            this.props.menuItemsActive === null &&
                              this.props.setMenuItemsActive(
                                this.props.menuItemsActive !== i ? i : null,
                              );
                          }, 30);
                        }}></span>
                    )}

                    {mn.items && (
                      <ul
                        className={
                          'menu-item ' + (this.props.menuItemsActive === i && 'menu-item-active')
                        }>
                        {mn?.items?.map((mnn, index) => (
                          <li key={mnn.url + '_' + index}>
                            <a href={mnn.url}>{mnn.name}</a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
                <li className="d-hidden">
                  <a href={localStorage.getItem('token') ? '/home' : '/login'}>
                    <span className="ico ico-lc"></span>личный кабинет
                  </a>
                </li>
                <li className="d-hidden">
                  <a href="https://cme.agp.academy/auth/">
                    <span className="ico ico-lock"></span>ВХОД ДЛЯ ВРАЧЕЙ
                  </a>
                </li>
              </ul>
            </div>
            <div className="header__logo-right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="97"
                height="43"
                viewBox="0 0 97 43"
                fill="none">
                <path
                  d="M25.3271 0.501953H0.076179V4.42709H24.0341C24.7186 4.42709 25.251 4.95548 25.251 5.71031V18.769C25.251 19.4483 24.7186 19.9767 24.0341 19.9767H5.17199C4.48748 19.9767 3.95508 19.4483 3.95508 18.6935V13.4096C3.95508 12.7303 4.48748 12.1264 5.17199 12.1264H21.3721V8.27675H3.87902C1.74943 8.27675 0.00012207 10.0129 0.00012207 12.1264V19.9012C0.00012207 22.0147 1.74943 23.7509 3.87902 23.7509H25.251C27.3806 23.7509 29.1299 22.0147 29.1299 19.9012V4.35161C29.206 2.23807 27.4567 0.501953 25.3271 0.501953Z"
                  fill="#159BD7"
                />
                <path
                  d="M43.5046 6.01172L46.5469 15.0697C46.9271 16.202 47.1553 16.4284 47.7638 16.4284H48.3722V18.0136H41.7553V16.4284H42.2877C42.8201 16.4284 43.2764 16.353 43.2764 16.1265C43.2764 15.9755 43.2764 15.7491 43.2004 15.4472L42.8201 14.1639H39.0933L38.8651 14.9188C38.6369 15.5226 38.5609 15.8246 38.5609 16.051C38.5609 16.353 39.1693 16.4284 39.7017 16.4284H40.2341V18.0136H34.9102V16.4284H35.5186C36.1271 16.4284 36.4313 16.202 36.7355 15.0697L39.7778 6.01172H43.5046ZM39.7017 12.6543H42.3637L41.0708 8.72912L39.7017 12.6543Z"
                  fill="black"
                />
                <path
                  d="M49.4371 8.27622C49.4371 7.74784 49.2849 7.52139 48.7525 7.52139H47.9159V6.01172H52.4033V10.5407C53.0878 9.78589 53.9244 9.25751 54.761 9.25751C57.4991 9.25751 58.4878 11.371 58.4878 13.7865C58.4878 16.202 57.4991 18.3155 54.761 18.3155C53.6202 18.3155 52.6315 17.9381 51.9469 16.9568L51.4145 18.0891H49.361V8.27622H49.4371ZM52.4033 13.7865C52.4033 15.6736 52.7836 16.7304 53.8484 16.7304C54.8371 16.7304 55.2934 15.5981 55.2934 13.7865C55.2934 11.9749 54.9132 10.8427 53.8484 10.8427C52.8596 10.8427 52.4033 11.9749 52.4033 13.7865Z"
                  fill="black"
                />
                <path
                  d="M59.5525 8.27622C59.5525 7.74784 59.4004 7.52139 58.868 7.52139H58.0314V6.01172H62.5188V10.5407C63.2033 9.78589 64.0399 9.25751 64.8765 9.25751C67.6146 9.25751 68.6033 11.371 68.6033 13.7865C68.6033 16.202 67.6146 18.3155 64.8765 18.3155C63.7357 18.3155 62.7469 17.9381 62.0624 16.9568L61.53 18.0891H59.4765V8.27622H59.5525ZM62.5948 13.7865C62.5948 15.6736 62.9751 16.7304 64.0399 16.7304C65.0286 16.7304 65.485 15.5981 65.485 13.7865C65.485 11.9749 65.1047 10.8427 64.0399 10.8427C62.9751 10.8427 62.5948 11.9749 62.5948 13.7865Z"
                  fill="black"
                />
                <path
                  d="M78.8711 13.7868C78.8711 16.6552 76.8936 18.3158 74.2316 18.3158C71.4936 18.3158 69.5921 16.7307 69.5921 13.7868C69.5921 10.9184 71.5696 9.25781 74.2316 9.25781C76.8936 9.25781 78.8711 10.9184 78.8711 13.7868ZM72.7105 13.7868C72.7105 15.9004 73.0907 16.8816 74.1555 16.8816C75.2203 16.8816 75.6006 15.9758 75.6006 13.7868C75.6006 11.6733 75.2203 10.692 74.1555 10.692C73.0907 10.7675 72.7105 11.6733 72.7105 13.7868Z"
                  fill="black"
                />
                <path
                  d="M91.7248 13.9389V15.373C91.7248 16.2034 91.5727 16.7318 90.8882 16.7318C90.5079 16.7318 90.2797 16.4298 90.2797 16.0524V11.0705H92.4854V9.56082H90.2797V6.16406H88.4543C88.3022 7.67373 87.8459 9.56082 86.0205 9.56082H83.2064V6.16406H81.4571C81.305 7.67373 80.8487 9.56082 79.0233 9.56082V10.995H80.2402V15.524C80.2402 17.4111 81.305 18.3169 83.1304 18.3169C85.336 18.3169 86.1726 17.3356 86.1726 15.524V13.9389H84.7276V15.373C84.7276 16.2034 84.5754 16.7318 83.8909 16.7318C83.4346 16.7318 83.2825 16.4298 83.2825 16.0524V11.0705H87.3135V15.5995C87.3135 17.4866 88.3022 18.3924 90.2036 18.3924C92.4093 18.3924 93.2459 17.4111 93.2459 15.5995V14.0143H91.7248V13.9389Z"
                  fill="black"
                />
                <path
                  d="M23.1214 32.431L22.589 32.3555L21.1439 33.9406L19.6228 34.7709L19.5468 35.2238H20.6115L20.0031 38.2432C19.7749 39.149 19.6989 40.3567 21.0679 40.3567C21.9806 40.3567 22.8172 40.0548 23.7299 39.7529V39.3C23.5017 39.3 23.3496 39.3 23.1214 39.3C22.4369 39.3 22.1327 39.2245 21.9806 39.0735C21.8285 38.9225 21.8285 38.6961 21.9806 37.9412L22.513 35.2993H24.2623L24.4905 34.3935H22.7411L23.1214 32.431Z"
                  fill="black"
                />
                <path
                  d="M26.9243 39.6769C26.468 39.6769 26.2398 39.224 26.2398 38.4691C26.2398 37.2614 26.7722 34.7705 27.6849 34.7705C28.1412 34.7705 28.3694 35.2234 28.3694 35.9782C28.3694 37.1104 27.9131 39.6769 26.9243 39.6769ZM27.9131 34.0156C25.8595 34.0156 24.3384 35.8272 24.3384 37.8653C24.3384 39.4504 25.3271 40.3562 26.6961 40.3562C28.7497 40.3562 30.2708 38.5446 30.2708 36.5066C30.2708 34.9214 29.2821 34.0156 27.9131 34.0156Z"
                  fill="black"
                />
                <path
                  d="M37.1159 32.431L36.5835 32.3555L35.1385 33.9406L33.6173 34.7709L33.5413 35.2238H34.6821L33.9976 38.2432C33.7694 39.149 33.6934 40.3567 35.0624 40.3567C35.9751 40.3567 36.8117 40.0548 37.7244 39.7529V39.3C37.4962 39.3 37.3441 39.3 37.1159 39.3C36.4314 39.3 36.1272 39.2245 35.9751 39.0735C35.823 38.9225 35.823 38.6961 35.9751 37.9412L36.5075 35.2993H38.2568L38.485 34.3935H36.7357L37.1159 32.431Z"
                  fill="black"
                />
                <path
                  d="M44.3416 38.6217L44.874 36.5081C45.1783 35.2249 44.874 34.0926 43.6571 34.0926C42.8965 34.0926 42.2881 34.4701 41.5275 35.1494H41.3754L42.2881 30.6959L41.9839 30.5449L39.1698 31.1488V31.4507L40.0824 32.0546L38.3331 40.2068H40.2346L41.1472 35.9043C41.6036 35.6023 42.0599 35.4513 42.3641 35.4513C42.8965 35.4513 43.0487 35.8288 42.8965 36.5836L42.3641 38.7726C42.2881 38.9991 42.2881 39.2255 42.2881 39.3765C42.2881 39.9804 42.5923 40.3578 43.4289 40.3578C44.0374 40.3578 44.7219 40.1313 45.3304 39.7539L45.2543 39.3765C44.2656 39.301 44.1895 39.2255 44.3416 38.6217Z"
                  fill="black"
                />
                <path
                  d="M48.9808 34.695C49.285 34.695 49.4371 34.9214 49.4371 35.2234C49.4371 36.0537 48.9047 36.8085 47.1554 36.9595C47.3836 35.6763 48.3723 34.695 48.9808 34.695ZM51.1104 35.5253C51.1104 34.695 50.3498 34.0156 49.1329 34.0156C47.1554 34.0156 45.33 35.6763 45.33 37.9408C45.33 39.224 46.1667 40.2808 47.916 40.2808C49.0568 40.2808 49.9695 39.7524 50.578 38.7711L50.3498 38.5446C49.8174 38.8466 49.285 38.9975 48.8287 38.9975C47.7639 38.9975 47.2315 38.5446 47.2315 37.4879C47.3075 37.4879 47.3836 37.4879 47.5357 37.4879C49.5132 37.5633 51.1104 36.884 51.1104 35.5253Z"
                  fill="black"
                />
                <path
                  d="M59.1724 31.3752C59.6288 32.1301 60.3133 32.432 60.7696 32.432C61.3781 32.432 61.6823 31.9791 61.6823 31.5262C61.6823 30.9223 61.1499 30.5449 60.3133 30.5449C59.5527 30.5449 58.9442 30.7714 58.3358 31.1488C57.347 31.8281 56.7386 32.8094 56.4344 34.1681V34.2436L54.9132 34.772L54.8372 35.3004H56.2823L55.5217 39.9049C55.2935 41.49 55.0653 41.9429 54.8372 42.1694C54.4569 41.49 53.8484 41.1126 53.316 41.1126C52.7836 41.1126 52.4034 41.5655 52.4034 42.0184C52.4034 42.6223 52.9357 42.9997 53.7724 42.9997C54.3808 42.9997 55.1414 42.7732 55.8259 42.3203C56.7386 41.641 57.271 40.5087 57.5752 38.6971L58.1837 35.2249H59.8569L60.0851 34.3191H58.4118L58.4879 34.0172C58.64 32.2056 58.8682 31.6772 59.1724 31.3752Z"
                  fill="black"
                />
                <path
                  d="M66.0938 38.5446L67.0064 34.1666L66.7022 34.0156L63.9642 34.6195V34.9969L64.7247 35.5253L64.1163 38.5446C63.6599 38.8466 63.2036 38.9975 62.8994 38.9975C62.367 38.9975 62.2149 38.6201 62.367 37.8653L63.2036 34.2421L62.8994 34.0911L60.1613 34.695L60.0853 35.0724L60.9219 35.6008L60.3895 38.0162C60.0853 39.2995 60.3895 40.4317 61.6064 40.4317C62.443 40.4317 63.1275 39.9033 63.8881 39.224H64.0402C63.9642 39.8278 64.1923 40.3562 65.029 40.3562C65.7135 40.3562 66.3219 40.1298 66.9304 39.7524L67.0064 39.3749C66.0177 39.2995 65.9416 39.2995 66.0938 38.5446Z"
                  fill="black"
                />
                <path
                  d="M68.2992 31.0733V31.4507L69.2119 32.0546L67.7668 38.7726C67.5386 39.7539 67.7668 40.3578 68.8316 40.3578C69.4401 40.3578 70.1246 40.1313 70.8091 39.7539L70.733 39.452C69.5922 39.3765 69.5922 39.301 69.7443 38.6217L71.4175 30.6959L71.1133 30.5449L68.2992 31.0733Z"
                  fill="black"
                />
                <path
                  d="M72.0261 31.0733V31.4507L72.9388 32.0546L71.4937 38.7726C71.2656 39.7539 71.4937 40.3578 72.5585 40.3578C73.167 40.3578 73.8515 40.1313 74.4599 39.7539L74.3839 39.452C73.243 39.3765 73.243 39.301 73.3951 38.6217L75.0684 30.6959L74.7642 30.5449L72.0261 31.0733Z"
                  fill="black"
                />
                <path
                  d="M78.491 34.695C78.7952 34.695 78.9473 34.9214 78.9473 35.2234C78.9473 36.0537 78.4149 36.8085 76.6656 36.9595C76.8938 35.6763 77.8065 34.695 78.491 34.695ZM80.6206 35.5253C80.6206 34.695 79.86 34.0156 78.6431 34.0156C76.6656 34.0156 74.8403 35.6763 74.8403 37.9408C74.8403 39.224 75.6769 40.2808 77.4262 40.2808C78.5671 40.2808 79.4797 39.7524 80.0882 38.7711L79.86 38.5446C79.3276 38.8466 78.7952 38.9975 78.3389 38.9975C77.2741 38.9975 76.7417 38.5446 76.7417 37.4879C76.8178 37.4879 76.8938 37.4879 77.0459 37.4879C78.9473 37.5633 80.6206 36.884 80.6206 35.5253Z"
                  fill="black"
                />
                <path
                  d="M84.1191 34.0156C82.2938 34.0156 81.3811 35.0724 81.3811 36.1292C81.3811 36.9595 81.9135 37.3369 82.7501 37.7143L83.2825 37.9408C84.0431 38.2427 84.1952 38.4691 84.1952 38.8466C84.1952 39.2995 83.967 39.6014 83.5107 39.6014C83.2064 39.6014 82.9022 39.5259 82.598 39.2995C82.598 38.3937 82.0656 37.8653 81.5332 37.8653C81.0768 37.8653 80.6966 38.2427 80.6966 38.7711C80.6966 39.6769 81.8374 40.2053 83.2825 40.2053C85.1079 40.2053 85.9445 39.073 85.9445 38.0917C85.9445 37.2614 85.4121 36.884 84.5755 36.4311L84.0431 36.2046C83.3586 35.9027 83.1304 35.6763 83.1304 35.2988C83.1304 34.9214 83.4346 34.544 83.8149 34.544C83.967 34.544 84.1952 34.6195 84.3473 34.695C84.4234 35.4498 84.9558 36.0537 85.4882 36.0537C85.9445 36.0537 86.2487 35.7517 86.2487 35.2234C86.2487 34.695 85.4121 34.0156 84.1191 34.0156Z"
                  fill="black"
                />
                <path
                  d="M90.2035 32.431L89.6711 32.3555L88.226 33.9406L86.7049 34.7709L86.6288 35.2238H87.7697L87.1612 38.1677C86.933 39.0735 86.857 40.2812 88.226 40.2812C89.1387 40.2812 89.9753 39.9793 90.888 39.6774V39.2245C90.6598 39.2245 90.5077 39.2245 90.2795 39.2245C89.595 39.2245 89.2908 39.149 89.1387 38.998C88.9866 38.847 88.9866 38.6206 89.1387 37.8658L89.6711 35.2238H91.4204L91.6486 34.318H89.8232L90.2035 32.431Z"
                  fill="black"
                />
                <path
                  d="M16.2001 40.207C15.8199 40.207 15.5156 39.9051 15.5156 39.6032C15.5156 39.2258 15.8199 38.9238 16.2001 38.9238C16.5804 38.9238 16.8086 39.2258 16.8086 39.6032C16.8086 39.9051 16.5044 40.207 16.2001 40.207Z"
                  fill="black"
                />
                <path
                  d="M92.8655 40.207C92.4852 40.207 92.1809 39.9051 92.1809 39.6032C92.1809 39.2258 92.4852 38.9238 92.8655 38.9238C93.2457 38.9238 93.4739 39.2258 93.4739 39.6032C93.55 39.9051 93.2457 40.207 92.8655 40.207Z"
                  fill="black"
                />
                <path
                  d="M0.684771 30.9977L0.608714 31.2996L1.82562 31.6016L0.0763158 39.2254C0.000258859 39.5273 0.0763158 39.8293 0.22843 39.9802C0.380543 40.1312 0.532657 40.2067 0.760828 40.2067C1.21717 40.2067 1.90168 39.9802 2.43408 39.6783L2.35802 39.4519C1.67351 39.4519 1.29323 39.4519 1.21717 39.3009C1.14111 39.2254 1.14111 39.1499 1.21717 38.999L3.11859 30.4693L2.96648 30.3184L0.684771 30.9977Z"
                  fill="black"
                />
                <path
                  d="M10.9523 30.9222C11.1044 31.6016 11.4086 31.979 11.941 31.979C12.3974 31.979 12.7016 31.6771 12.7016 31.2996C12.7016 30.8467 12.3213 30.3184 11.4086 30.3184C10.8002 30.3184 10.2678 30.4693 9.81142 30.8467C8.97479 31.5261 8.51845 32.2809 8.29028 33.7151L8.21422 34.0925L6.76914 34.47L6.69309 34.8474H8.06211L7.14943 40.2067C6.99731 41.1125 6.76914 41.7918 6.46492 42.1693C6.16069 41.7164 5.85646 41.5654 5.47618 41.5654C5.01983 41.5654 4.71561 41.7918 4.71561 42.1693C4.71561 42.5467 5.01983 42.9241 5.62829 42.9241C6.08463 42.9241 6.61703 42.6977 6.99731 42.3202C7.75788 41.5654 8.06211 40.886 8.4424 38.9235L9.12691 34.9229H10.572L10.7241 34.2435H9.27902L9.35508 33.6396C9.65931 31.9035 10.1917 30.9222 10.9523 30.9222Z"
                  fill="black"
                />
                <path
                  d="M13.5382 36.3562C13.0818 36.7336 12.3213 36.8846 11.4086 36.7336C11.7128 35.2994 12.4734 34.3181 13.31 34.3181C13.7663 34.3181 14.1466 34.6956 14.1466 35.1485C14.0706 35.6768 13.9185 36.1297 13.5382 36.3562ZM14.3748 38.6962C13.9185 39.1491 13.3861 39.3755 12.7776 39.3755C12.3213 39.3755 11.941 39.2246 11.6368 38.9226C11.3325 38.5452 11.1804 37.9413 11.2565 37.262C11.4086 37.262 11.5607 37.262 11.6368 37.262C13.8424 37.262 15.0593 36.5072 15.0593 35.2239C15.0593 34.3936 14.3748 33.8652 13.3861 33.8652C12.4734 33.8652 11.6368 34.3181 10.9522 35.2239C10.3438 35.9788 9.9635 37.0355 9.9635 37.9413C9.9635 39.3 10.8001 40.2058 12.1692 40.2058C13.1579 40.2058 14.0706 39.7529 14.603 38.9226V38.8471L14.3748 38.6962Z"
                  fill="black"
                />
                <path
                  d="M5.85649 39.6775L5.78043 39.451C5.09592 39.451 4.79169 39.451 4.63958 39.3C4.63958 39.2246 4.63958 39.1491 4.63958 38.9981L5.78043 34.0162L5.62832 33.8652L3.42267 34.5446V34.8465L4.48747 35.1485L3.57478 39.3C3.49873 39.602 3.57478 39.9039 3.7269 40.0549C3.87901 40.2058 4.03112 40.2813 4.25929 40.2813C4.63958 40.2058 5.24803 39.9794 5.85649 39.6775Z"
                  fill="black"
                />
                <path
                  d="M5.40012 32.8099C5.7804 32.8099 6.08463 32.5079 6.08463 32.1305C6.08463 31.7531 5.7804 31.4512 5.40012 31.4512C5.01983 31.4512 4.71561 31.7531 4.71561 32.1305C4.71561 32.5079 5.01983 32.8099 5.40012 32.8099Z"
                  fill="black"
                />
                <path
                  d="M94.0824 34.167V33.7141H94.5388C94.6909 33.7141 94.767 33.7895 94.767 33.9405C94.767 34.0915 94.6909 34.167 94.5388 34.167H94.0824ZM93.6261 35.2237C93.6261 35.2992 93.7022 35.2992 93.7022 35.2992H94.0064C94.0824 35.2992 94.0824 35.2237 94.0824 35.2237V34.6199H94.3867L94.6148 35.2992C94.6148 35.3747 94.6909 35.3747 94.6909 35.3747H94.9951C95.0712 35.3747 95.1472 35.2992 95.0712 35.2237L94.767 34.6199C94.9191 34.5444 95.2233 34.3179 95.2233 33.9405C95.2233 33.4876 94.843 33.1857 94.4627 33.1857H93.5501C93.474 33.1857 93.474 33.2612 93.474 33.2612V35.2237H93.6261ZM92.7134 34.2424C92.7134 33.3366 93.474 32.5818 94.3867 32.5818C95.2994 32.5818 96.0599 33.3366 96.0599 34.2424C96.0599 35.1482 95.2994 35.9031 94.3867 35.9031C93.474 35.9031 92.7134 35.2237 92.7134 34.2424ZM92.2571 34.2424C92.2571 35.4502 93.2458 36.356 94.3867 36.356C95.6036 36.356 96.5163 35.3747 96.5163 34.2424C96.5163 33.0347 95.5275 32.1289 94.3867 32.1289C93.2458 32.1289 92.2571 33.1102 92.2571 34.2424Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className="header__btn">
              <div className={'hamburger '}>
                <div
                  className="hamburger-box"
                  onClick={() => {
                    this.props.clickMenu();
                  }}>
                  <div className="hamburger-inner"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header__2">
          <div className="container">
            <ul className="menu-2">
              <li>
                <a href={localStorage.getItem('token') ? '/home' : '/login'}>
                  <span className="ico ico-lc"></span>личный кабинет
                </a>
              </li>
              <li>
                <a href="https://cme.agp.academy/auth/">
                  <span className="ico ico-lock"></span>ВХОД ДЛЯ ВРАЧЕЙ
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
