import React, { Component } from 'react';
import { Switch, Route, Link, NavLink } from 'react-router-dom';
require('es6-promise').polyfill();
require('isomorphic-fetch');
export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}
  render() {
    return (
      <div className="side__nav">
        <div className="side__nav-user__profile">
          <div className="side__nav-ico_user">
            {this.props?.profile?.[0]?.lastname
              ? this.props?.profile?.[0]?.lastname.slice(0, 1)
              : 'Ф'}
            {this.props?.profile?.[0]?.firstname
              ? this.props?.profile?.[0]?.firstname.slice(0, 1)
              : 'И'}
          </div>
          <div className="side__nav-name">
            {this.props?.profile?.[0]?.lastname ? this.props?.profile?.[0]?.lastname : 'Фамилия'}{' '}
            {this.props?.profile?.[0]?.firstname ? this.props?.profile?.[0]?.firstname : 'Имя'}
          </div>
        </div>
        <ul className="menu-3">
          {this.props.menu.length &&
            this.props.menu?.map((mn, index) => (
              <li key={mn.link + '_' + index}>
                <NavLink
                  to={mn.url}
                  exact
                  onClick={() => {
                    // this.props.clickMenuClose();
                    this.props.setStatement();
                    localStorage.removeItem('rmaId');
                    localStorage.removeItem('rmaAddressId');
                    localStorage.removeItem('typeReturnMoney');
                  }}>
                  <span className={'ico ico-' + mn.parameters?.icon}></span>
                  {mn.name}
                </NavLink>
              </li>
            ))}
          <li>
            <a
              href="#"
              onClick={() => {
                this.props.submit('close');
                localStorage.removeItem('rmaId');
                localStorage.removeItem('rmaAddressId');
                localStorage.removeItem('typeReturnMoney');
              }}>
              <span className="ico ico-exit"></span> Выйти
            </a>
          </li>
        </ul>
      </div>
    );
  }
}
