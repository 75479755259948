import React, { useState, useEffect } from 'react';
import SideNav from './SideNav';
import OrderList from './OrderList';
import Paginate from './Paginate';

export default function App(props) {
  useEffect(() => {
    !localStorage.getItem('token') && window.location.replace('/login');
    document.body.classList.add('order');
    props.setErrTextApi('');
    orderList();
    orderListHist();
  }, []);
  const [listOrder, setListOrder] = useState([]);
  const [listOrderHist, setListOrderHist] = useState([]);
  const [listOrderHistPagin, setListOrderHistPagin] = useState([]);
  const [listOrderPagin, setListOrderPagin] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const orderList = (page = 1, per_page = 5) => {
    var myHeaders = new Headers();
    myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
    fetch(props.API_URL + '/order/list?per_page=' + per_page + '&page=' + page, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem('token');
        window.location.replace('/login');
      } else {
        response.json().then(function (data) {
          if (data.status === true) {
            setListOrder([...data.data.list]);
            setListOrderPagin(data.meta.pagination);
          } else {
            console.log(data);
          }
        });
      }
    });
  };
  const orderListHist = (page = 1, per_page = 5) => {
    var myHeaders = new Headers();
    myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
    fetch(props.API_URL + '/order/list-history?per_page=' + per_page + '&page=' + page, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem('token');
        window.location.replace('/login');
      } else {
        response.json().then(function (data) {
          if (data.status === true) {
            setListOrderHist([...data.data.list]);
            setListOrderHistPagin(data.meta.pagination);
          } else {
            console.log(data);
          }
        });
      }
    });
  };
  const handlePageClick = (e) => {
    orderList(e.selected + 1);
  };
  const handlePageClick2 = (e) => {
    orderListHist(e.selected + 1);
  };

  return (
    <div className="content d-flex m-f-column">
      <SideNav
        menu={props.menu}
        profile={props.profile}
        submit={props.submit}
        setStatement={props.setStatement}
      />
      <div className="content__right">
        <div className="content__page">
          <div className="current current__tab">
            <div
              className={
                'current-title current__tab-title current__tab-title-1' +
                (activeTab === 1 ? ' active' : '')
              }
              onClick={() => setActiveTab(1)}>
              Текущие заказы
              <span>{listOrderPagin?.count > 0 && listOrderPagin?.count}</span>
            </div>
            <div className={'current-items' + (activeTab === 1 ? ' active' : '')}>
              {listOrder?.length > 0 && (
                <div className="d-flex current-items-yours-block">
                  <div className="current-items-yours d-hidden">
                    Ваши заказы<span>{listOrderPagin?.count > 0 && listOrderPagin?.count}</span>
                  </div>
                </div>
              )}
              {listOrder?.length > 0 ? (
                <OrderList list={listOrder} API_URL={props.API_URL} />
              ) : (
                <div className="current-items-null">У Вас нет текущих заказов</div>
              )}
              {listOrder?.length > 0 && listOrderPagin?.count > 1 && (
                <Paginate listPagin={listOrderPagin} handlePageClick={handlePageClick} />
              )}
              {listOrder?.length > 0 && listOrderPagin?.count / listOrderPagin?.per_page > 1 && (
                <div className="d-hidden allProduct" onClick={() => orderList(1, 100)}>
                  Показать все заказы
                </div>
              )}
            </div>

            <div
              className={
                'current-title current__tab-title current__tab-title-2' +
                (activeTab === 2 ? ' active' : '')
              }
              onClick={() => setActiveTab(2)}>
              История заказов
              <span>{listOrderHistPagin?.count > 0 && listOrderHistPagin?.count}</span>
            </div>
            <div className={'current-items' + (activeTab === 2 ? ' active' : '')}>
              {listOrderHist?.length > 0 && (
                <div className="d-flex current-items-yours-block">
                  <div className="current-items-yours d-hidden">
                    История заказов
                    <span>{listOrderHistPagin?.count > 0 && listOrderHistPagin?.count}</span>
                  </div>
                </div>
              )}
              {listOrderHist?.length > 0 ? (
                <OrderList list={listOrderHist} API_URL={props.API_URL} />
              ) : (
                <div className="current-items-null">У Вас нет истории заказов</div>
              )}
              {listOrderHist?.length > 0 && listOrderHistPagin?.count > 1 && (
                <Paginate listPagin={listOrderHistPagin} handlePageClick={handlePageClick2} />
              )}
              {listOrderHist?.length > 0 &&
                listOrderHistPagin?.count / listOrderHistPagin?.per_page > 1 && (
                  <div className="d-hidden allProduct" onClick={() => orderListHist(1, 100)}>
                    Показать все заказы
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
