import React, { useState, useEffect } from 'react';
import Banner from './Banner';
import Input from './Input';
import Modal from './Modal';
import SideNav from './SideNav';
export default function App(props) {
  useEffect(() => {
    localStorage.getItem('token') && window.location.replace('/home');
    props.setRoomes([...props.formInput2[props.nameForm]]);
    document.body.classList.add('home');
    // props.runModalShow(4);
    props.setSubActive2(false);
  }, []);

  const forPassword = (e) => {
    props.setRoomesN([...props.formInput2['forEmail']]);
    props.setSubActive2(false);
    props.runModalShow(e);
  };

  return (
    <div className="content__max">
      <div className="content__page">
        {props.errTextApi !== '' && (
          <div
            className="form__item form__err"
            dangerouslySetInnerHTML={{ __html: props.errTextApi }}></div>
        )}
        <div className="form text-center d-flex d-aling-center d-f-column">
          <h1 className="form-title ">Вход в личный кабинет</h1>
          <Input
            rooms={props.roomes}
            setRooms={props.setRoomes}
            setValidation={props.setValidation}
            togglePass={props.togglePass}
            handleroom={props.handleroom}
            forPassword={forPassword}
            setErrTextApi={props.setErrTextApi}
          />
          <button
            onClick={() => {
              props.submit(props.nameForm);
            }}
            className={'btn' + (!props.subActive ? ' btn-grey' : ' btn-blue2')}>
            Войти
          </button>
          <p className="form__text-info">
            У меня нет учетной записи, <a href="/registration">зарегистрироваться</a>
          </p>
          <br />
          <br />
        </div>
      </div>
      <div className="modals__list">
        <Modal
          class={'middle'}
          modalShow={props.modalShow}
          modalClose={props.modalClose}
          modalData={props.modals[3]}
          setErrTextApi={props.setErrTextApi}
          key={props.modals[3].id}>
          {props.errTextApi !== '' && (
            <div
              className="form__item form__err"
              dangerouslySetInnerHTML={{ __html: props.errTextApi }}></div>
          )}
          <div className="form__title form__title-2">ВОССТАНОВЛЕНИЕ ПАРОЛЯ</div>
          <div className="form__text">
            <div className="text-center form__title-2-min">
              Ниже необходимо ввести адрес Вашей электронной почты. На&nbsp;него мы&nbsp;вышлем Вам
              письмо, содержащее ссылку для восстановления пароля.
            </div>
          </div>
          <div className="d-flex text-center">
            <Input
              rooms={props.roomesN}
              setRooms={props.setRoomes}
              setValidation={props.setValidation}
              togglePass={props.togglePass}
              handleroom={props.handleroomS}
              forPassword={forPassword}
            />
          </div>
          <div className="d-flex modal-box-btn">
            <button
              onClick={() => {
                props.modalClose();
              }}
              className="btn btn-grey2 btn-inline">
              Отмена
            </button>
            <button
              onClick={() => {
                props.submit('forEmail');
              }}
              className={' btn btn-inline' + (!props.subActive2 ? ' btn-grey' : ' btn-blue2')}>
              Отправить письмо
            </button>
          </div>
        </Modal>
        <Modal
          class={'middle'}
          modalShow={props.modalShow}
          modalClose={props.modalClose}
          modalData={props.modals[4]}
          key={props.modals[4].id}>
          <div className="form__title form__title-2">ВОССТАНОВЛЕНИЕ ПАРОЛЯ</div>
          <div className="form__text">
            <div className="text-center form__title-2-min">
              Спасибо! <br />
              На&nbsp;адрес Вашей электронной почты было выслано письмо <br className="m-hidden" />
              с&nbsp;инструкциями по&nbsp;восстановлению пароля.
            </div>
          </div>
          <p className="form__text-info">
            Не&nbsp;получили письмо с&nbsp;подтверждением? <br />
            Рекомендуем проверить папку спама.
          </p>
          <br /> <br />
          <div className="d-flex modal-box-btn">
            <button
              onClick={() => {
                forPassword(3);
              }}
              className="btn btn-grey2 btn-inline">
              Выслать письмо повторно
            </button>
            <button
              onClick={() => {
                props.modalClose();
              }}
              className="btn btn-blue2 btn-inline">
              Закрыть
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}
