import React, { useState, useEffect } from 'react';
export default function App(props) {
  useEffect(() => {}, []);

  return (
    <div className="content__max">
      <div className="content__page">
        <div className="form text-center d-flex d-aling-center d-f-column">
          <div className="form-title ">Спасибо за&nbsp;регистрацию!</div>
          <p className="text-center">
            Вскоре Вы&nbsp;получите подтверждение по&nbsp;электронной почте{' '}
            <br className="m-hidden" />
            на&nbsp;указанный Вами адрес:{' '}
            <span className="colorBlue">{localStorage.getItem('email')}</span>
          </p>
          <p className="form__text-info">
            Не&nbsp;получили письмо с&nbsp;подтверждением? <br />
            Рекомендуем проверить папку спама.
          </p>
          <br />
          <br />
          <br />
          <br className="m-hidden" />
          <br className="m-hidden" />
          <br className="m-hidden" />
          <br className="m-hidden" />
          <br className="m-hidden" />
          <br className="m-hidden" />
          <br className="m-hidden" />
          <br className="m-hidden" />
          <br className="m-hidden" />
          <br className="m-hidden" />
          <br className="m-hidden" />
          <br className="m-hidden" />
        </div>
      </div>
    </div>
  );
}
