import React, { Component } from 'react';
import logo from '../assets/img/first-logo.png';
import logo_abbot from '../assets/img/logo_abbot.svg';
require('es6-promise').polyfill();
require('isomorphic-fetch');
export default function footer(props) {
  const menu1 = [
    {
      id: 1,
      name: 'support@activia14daychallenge.com',
      link: 'mailto:support@activia14daychallenge.com',
      target: false,
    },
    { id: 2, name: '800 124 1573', link: 'tel:+78001241573', target: false },
    { id: 3, name: 'Leave us a message', link: '#', target: false },
  ];
  const menu2 = [
    { id: 1, name: 'Our Products', link: '/#product', target: false },
    { id: 2, name: 'Recipes', link: '#', target: false },
    { id: 3, name: 'Health tips', link: '#', target: false },
  ];
  return (
    <div className="footer">
      <div className="footer-block">
        <div className="container ">
          <div className="footer-block-content d-flex">
            <div className="footer-block-logo">
              <img src={logo} className="logo-footer" alt="Logo" />
            </div>
            <div className="footer-block-text">
              <p>
                &copy;2024&nbsp;Эбботт. Сайт официального представителя производителя FreeStyle
                Libre<sup className="reg">&reg;</sup> в&nbsp;России. Предназначен только для лиц,
                находящихся на&nbsp;территории Российской Федерации. ООО &laquo;Эбботт
                Лэбораториз&raquo;. 125171, Москва, Ленинградское шоссе, 16А, стр.&nbsp;1, ОГРН
                1077746154859. <br/>
                Система Flash мониторинга глюкозы FreeStyle Libre РУ &#8470;РЗН 2018/6764
                от&nbsp;23.11.2023 (выдано взамен РУ &#8470;РЗН 2018/6764 от&nbsp;11.12.2020).{' '}
                <br/>                Система Flash мониторинга глюкозы FreeStyle Libre 2 РУ &#8470;РЗН 2022/16406
                от 23.11.2023 (выдано взамен РУ &#8470;РЗН 2022/16406 от 24.01.2022).{' '}
                <br/>
                Форма корпуса датчика, FreeStyle, Libre и&nbsp;связанные с&nbsp;ними торговые марки
                являются собственностью компании Abbott. Иные товарные знаки принадлежат другим
                соответствующим правообладателям. iPhone и&nbsp;App Store являются торговыми марками
                компании Apple Inc.
              </p>
              <p>
                1. Приложение FreeStyle LibreLink (ФриСтайл ЛибреЛинк) совместимо только
                с&nbsp;определенными мобильными устройствами и&nbsp;операционными системами.
                Проверяйте на&nbsp;<a href="https://freestyleserver.com/distribution/fxaa20.aspx?product=ifu_art39109_003&version=latest&os=all&region=ous&language=xx_yy">сайте</a> информацию о&nbsp;совместимости устройств
                перед использованием. Для использования FreeStyle LibreLink требуется регистрация
                в&nbsp;LibreView (ЛибреВью).
                <br />
                2. Приложение LibreLinkUp (ЛибреЛинкАп) совместимо только с&nbsp;определенными
                мобильными устройствами и&nbsp;операционными системами. Проверяйте на&nbsp;сайте{' '}
                <a href="https://www.librelinkup.ru">www.librelinkup.ru</a> информацию
                о&nbsp;совместимости устройств перед использованием. Использование приложения
                LibreLinkUp и&nbsp;FreeStyle LibreLink требует регистрации в&nbsp;LibreView.
                Мобильное приложение LibreLinkUp не&nbsp;предназначено для использования
                в&nbsp;качестве основного средства мониторинга: прежде чем делать медицинские
                заключения или корректировать терапию на&nbsp;основании информации, полученной
                от&nbsp;приложения, пользователи должны обращаться к&nbsp;своему основному
                устройству и\или консультироваться с&nbsp;лечащим врачом.
                <br />
                3. Для использования FreeStyle LibreLink требуется регистрация в&nbsp;LibreView.
                Автоматическая загрузка требует наличия интернет-соединения через Wi-Fi или
                по&nbsp;сотовой связи. Сайт LibreView совместим с&nbsp;определенными операционными
                системами и&nbsp;браузерами. Дополнительная информация доступна на&nbsp;сайте{' '}
                <a href="https://www.libreview.ru">www.libreview.ru</a>.
                <br />
                4. Сайт LibreView (ЛибреВью) совместим с&nbsp;определенными операционными системами
                и&nbsp;браузерами. Дополнительная информация доступна на&nbsp;сайте&nbsp;
                <a href="https://www.libreview.ru/chooseCountryLanguage">www.libreview.ru</a>.
              </p>
            </div>
            <div className="footer-block-logo2">
              <img src={logo_abbot} className="logo_abbot" alt="logo_abbot" />
            </div>
          </div>
          <div className="footer-block-contraindications">
            ИМЕЮТСЯ ПРОТИВОПОКАЗАНИЯ. ПЕРЕД ПРИМЕНЕНИЕМ НЕОБХОДИМО ПРОКОНСУЛЬТИРОВАТЬСЯ
            СО&nbsp;СПЕЦИАЛИСТОМ И&nbsp;ОЗНАКОМИТЬСЯ С&nbsp;ИНСТРУКЦИЕЙ. ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ
            ДОСТУПНА ПО&nbsp;НОМЕРУ ТЕЛЕФОНА: 8&#8209;800&#8209;100&#8209;88&#8209;07 (ежедневно
            с&nbsp;8.00 до&nbsp;20.00 без выходных по&nbsp;московскому времени).
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container d-flex d-f-row d-aling-center">
          <ul className="footer-menu">
            <li>
              <a
                onClick={() => {
                  props.menuFooterItemsActive === null &&
                    setTimeout(() => {
                      props.setMenuFooterItemsActive(props.menuFooterItemsActive !== 1 ? 1 : null);
                    }, 30);
                }}>
                Скачать инструкцию
              </a>
              <ul
                className={
                  'menu-item ' + (props.menuFooterItemsActive !== null && 'menu-item-active')
                }>
                <li>
                  <a
                    href="https://freestylediabetes.ru/upload/ART29720-001_rev-D-WEB.pdf"
                    target="_BLANK">
                    Датчик FreeStyle Libre 2
                  </a>
                </li>
                <li>
                  <a
                    href="https://freestyleserver.com/distribution/fxaa20.aspx?product=ifu_art39963_204&version=latest&os=all&region=ru&language=xx_yy"
                    target="_BLANK">
                      Датчик FreeStyle Libre
                  </a>
                </li>
                <li>
                  <a
                    href="https://freestyleserver.com/distribution/fxaa20.aspx?product=ifu_art39962_203&version=latest&os=all&region=ru&language=xx_yy"
                    target="_BLANK">
                    Сканер FreeStyle Libre
                  </a>
                </li>
                <li>
                  <a
                    href="https://freestylediabetes.ru/upload/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D0%BE%D0%B5_%D1%81%D0%BE%D0%BE%D0%B1%D1%89%D0%B5%D0%BD%D0%B8%D0%B5.pdf"
                    target="_BLANK">
                    Информационное сообщение
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="https://freestylediabetes.ru/upload/cookies.pdf" target="_BLANK">
                Политика по cookie-файлам
              </a>
            </li>
            <li>
              <a href="https://freestylediabetes.ru/upload/politika.pdf" target="_BLANK">
                Конфиденциальность
              </a>
            </li>
            <li>
              <a href="https://freestylediabetes.ru/upload/site_rules.pdf" target="_BLANK">
                Полные правила использования сайта
              </a>
            </li>
            <li>
              <a href="mailto:info@freestylediabetes.ru">info@freestylediabetes.ru</a>
            </li>
          </ul>
          {/* <div className="social d-flex">
            <a href="" target="_blank" className="ico ico-fb"></a>
            <a href="" target="_blank" className="ico ico-you"></a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
