import React, { useState, useEffect } from 'react';
import Banner from './Banner';
import Input from './InputN';
import ModalAggre from './ModalAggre';

export default function App(props) {
  useEffect(() => {
    document.body.classList.add('reg');
    setTimeout(() => {}, 300);
    getRegistration();
    props.setErrTextApi('');
  }, []);
  const ErrMas = [];
  const getRegistration = () => {
    fetch(props.API_URL + '/registration/fields', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.status === true) {
          props.setRoomesN([...Object.values(response.data.fields)]);
          props.setRoomesN((roomesN) =>
            roomesN?.map((list, index) =>
              index === index
                ? {
                    ...list,
                    ...(list['Err'] = false),
                    ...(list['err'] = false),
                    ...(list['value'] = ''),
                    ...(list['errorText'] = ''),
                  }
                : list,
            ),
          );
          props.setRoomesN((room) =>
            room?.map((list, index) =>
              index === index ? { ...list, value: list.fields[0].value } : list,
            ),
          );
        }
      });
  };

  return (
    <div className="content__max">
      <div className="content__page">
        {props.errTextApi !== '' && (
          <div
            className="form__item form__err"
            dangerouslySetInnerHTML={{ __html: props.errTextApi }}></div>
        )}
        <div className="form text-center d-flex d-aling-center d-f-column">
          <div className="form-title ">Регистрация</div>
          <Input
            rooms={props.roomesN}
            setRooms={props.setRoomes}
            setValidation={props.setValidation}
            togglePass={props.togglePass}
            handleroom={props.handleroom}
          />
          <button
            onClick={() => {
              props.submit('registr');
            }}
            className={'btn' + (!props.subActive ? ' btn-grey' : ' btn-blue2')}>
            Создать учетную запись
          </button>
        </div>
      </div>
      <div className="modals__list">
        <ModalAggre
          modalShow={props.modalShow}
          modalClose={props.modalClose}
          modalData={props.modals[6]}
          setErrTextApi={props.setErrTextApi}
          key={props.modals[6].id}
          errTextApi={props.errTextApi}
        />
      </div>
    </div>
  );
}
