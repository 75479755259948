import React, { useState, useEffect } from 'react';
import Input from './InputN';
import Modal from './Modal';
import ModalAggre from './ModalAggre';
let urlParams = '';
export default function App(props) {
  useEffect(() => {
    document.body.classList.add('confirm');
    urlParams = new URLSearchParams(window.location.search);
    urlParams.get('need_agree') === 'N' ? props.submit('activate', urlParams) : getForm();
  }, []);
  const [activate, setActivate] = useState(false);

  const getForm = () => {
    fetch(props.API_URL + '/registration/email-confirm-fields', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response.status === true) {
          props.setRoomesN([...Object.values(response.data.fields)]);
          props.setRoomesN((roomesN) =>
            roomesN?.map((list, index) =>
              index === index
                ? {
                    ...list,
                    ...(list['Err'] = false),
                    ...(list['err'] = false),
                    ...(list['value'] = ''),
                    ...(list['errorText'] = ''),
                  }
                : list,
            ),
          );
          props.setRoomesN((room) =>
            room?.map((list, index) =>
              index === index ? { ...list, value: list.fields[0].value } : list,
            ),
          );
        }
      });
  };

  urlParams = new URLSearchParams(window.location.search);
  return (
    <div className="content__max">
      <div className="content__page">
        {props.errTextApi !== '' && (
          <div
            className="form__item form__err"
            dangerouslySetInnerHTML={{ __html: props.errTextApi }}></div>
        )}
        {urlParams?.get('need_agree') === 'N' ? (
          <div className="form text-center d-flex d-aling-center d-f-column">
            <div className="form-title ">Спасибо за&nbsp;активацию!</div>
            <div className="form__text">
              <p className="text-center">
                Вы&nbsp;сможете поменять пароль в&nbsp;настройках <br className="m-hidden" />
                личного кабинета
              </p>
            </div>
            <br />
            <a href="/login" className="btn btn-blue2">
              Перейти к авторизации
            </a>
            <br />
            <br className="m-hidden" />
            <br className="m-hidden" />
            <br className="m-hidden" />
            <br className="m-hidden" />
            <br className="m-hidden" />
            <br className="m-hidden" />
            <br className="m-hidden" />
            <br className="m-hidden" />
            <br className="m-hidden" />
            <br className="m-hidden" />
            <br className="m-hidden" />
            <br className="m-hidden" />
            <br className="m-hidden" />
            <br className="m-hidden" />
          </div>
        ) : (
          <div className="form text-center d-flex d-aling-center d-f-column">
            <div className="form-title ">{activate}Спасибо за регистрацию!</div>
            <div className="form__text">
              <p className="text-center">
                Вам нужно создать пароль для доступа в&nbsp;личный кабинет{' '}
                <br className="m-hidden" />
                и&nbsp;предоставить разрешения на&nbsp;обработку персональных данных
              </p>
            </div>
            <Input
              rooms={props.roomesN}
              setRooms={props.setRoomes}
              setValidation={props.setValidation}
              togglePass={props.togglePass}
              handleroom={props.handleroom}
            />
            {}
            <button
              onClick={() => {
                props.submit('confirm_registration', urlParams);
              }}
              className={'btn' + (!props.subActive ? ' btn-grey' : ' btn-blue2')}>
              Активировать аккаунт
            </button>
          </div>
        )}
      </div>
      <div className="modals__list">
        <Modal
          class={'min'}
          modalShow={props.modalShow}
          modalClose={props.modalClose}
          modalData={props.modals[2]}
          key={props.modals[2].id}>
          <div className="form-title-min">{props.errTextApi}</div>
        </Modal>
        <ModalAggre
          modalShow={props.modalShow}
          modalClose={props.modalClose}
          modalData={props.modals[6]}
          setErrTextApi={props.setErrTextApi}
          key={props.modals[6].id}
          errTextApi={props.errTextApi}
        />
      </div>
    </div>
  );
}
