import React, { useState, useEffect } from 'react';
import SideNav from './SideNav';
import AppealProcess from './AppealProcess';
import Paginate from './Paginate';
import Modal from './Modal';
import ModalAggre from './ModalAggre';
import Input from './InputBlockMobile';
import Statement from './Statement';
import InputS from './Input';
import Application from './Application';
let urlParams = '';
export default function App(props) {
  useEffect(() => {
    document.body.classList.add('order');
    props.setErrTextApi('');
    rmaList();
    rmaListHist();
    props.submit('addressList');
    urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('id')) {
      localStorage.setItem('statemetId', urlParams.get('id'));
      localStorage.getItem('token')
        ? rmaDetail(urlParams.get('id'))
        : window.location.replace('/login');
      props.setStatement(true);
    }
    !localStorage.getItem('token') && window.location.replace('/login');
  }, []);
  const [listOrder, setListOrder] = useState([]);
  const [listOrderHist, setListOrderHist] = useState([]);
  const [listOrderHistPagin, setListOrderHistPagin] = useState([]);
  const [listOrderPagin, setListOrderPagin] = useState([]);
  const [listRma, setListRma] = useState([]);
  const [listRmaPagin, setListRmaPagin] = useState([]);
  const [listRmaDetail, setListRmaDetail] = useState([]);
  const [listRmaHist, setListRmaHist] = useState([]);
  const [listRmaHistPagin, setListRmaHistPagin] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [activeAddress, setActiveAddress] = useState(null);
  const [returnCash, setReturnCash] = useState(false);

  const [list, setList] = useState([]);
  const [dataF, setDataF] = useState([]);
  const rmaList = (page = 1, per_page = 5) => {
    var myHeaders = new Headers();
    myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
    fetch(props.API_URL + '/rma/list?per_page=' + per_page + '&page=' + page, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem('token');
        window.location.replace('/login');
      } else {
        response.json().then(function (data) {
          if (data.status === true) {
            setListRma([...data.data.list]);
            setListRmaPagin(data.meta.pagination);
          } else {
            console.log(data);
          }
        });
      }
    });
  };
  const rmaListHist = (page = 1, per_page = 5) => {
    var myHeaders = new Headers();
    myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
    fetch(props.API_URL + '/rma/list-history?per_page=' + per_page + '&page=' + page, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem('token');
        window.location.replace('/login');
      } else {
        response.json().then(function (data) {
          if (data.status === true) {
            setListRmaHist([...data.data.list]);
            setListRmaHistPagin(data.meta.pagination);
          } else {
            console.log(data);
          }
        });
      }
    });
  };
  const rmaDetail = (id) => {
    localStorage.setItem('rmaId', id);
    props.setRoomesH([...props.formInput2['changeCashAggre']]);
    props.setStatement(true);
    var myHeaders = new Headers();
    myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
    fetch(props.API_URL + '/rma/detail?id=' + id, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((response) => {
        if (response && response !== 'undefined' && response.status === true) {
          setListRmaDetail([response.data]);
          props.setSubActive(false);
          setActiveAddress(response.data.addressId);
          localStorage.setItem('typeReturnMoney', 'cash');
          localStorage.removeItem('statemetId');
        } else {
          console.log(response);
          props.setStatement(false);
        }
      });
  };
  const form = () => {
    props.setErrTextApi('');
    var myHeaders = new Headers();
    myHeaders.append('X-Authorization', 'Bearer ' + localStorage.getItem('token'));
    let url = '';
    localStorage.getItem('editAddressId')
      ? (url = props.API_URL + '/address/fields-cols?id=' + localStorage.getItem('editAddressId'))
      : (url = props.API_URL + '/address/fields-cols');
    fetch(url, {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }).then((response) => {
      if (response.status === 401) {
        localStorage.removeItem('token');
        window.location.replace('/login');
      } else {
        response.json().then(function (data) {
          if (data && data.status === true) {
            // localStorage.getItem('editAddressId') && localStorage.removeItem('editAddressId');
            let initMass = [];
            props.setRoomesN(...initMass);
            setDataF(data.data.fields);
            setDataF((roomsN) =>
              Object.entries(roomsN).map(
                ([key, value]) => (
                  (value['err'] = false),
                  (value['value'] = key === 'name_type' ? 'Господин' : ''),
                  (value['errorText'] = '')
                ),
              ),
            );
            props.setRoomesN(new Object(data.data.fields));
            // props.roomesN !== [] && console.log(props.roomesN);
            props.setBlockN([...data.data.blocks[0].block.cols]);
            setTimeout(() => {
              props.runModalShow(0, 'newAddress');
            }, 300);
          }
        });
      }
    });
  };
  const handlePageClick = (e) => {
    rmaList(e.selected + 1);
  };
  const handlePageClick2 = (e) => {
    rmaListHist(e.selected + 1);
  };

  return (
    <div className="content d-flex m-f-column">
      <SideNav
        menu={props.menu}
        profile={props.profile}
        submit={props.submit}
        setStatement={props.setStatement}
      />
      <div className="content__right">
        {!props.statement ? (
          <div className="content__page">
            <div className="current current__tab">
              <div
                className={
                  'current-title current__tab-title current__tab-title-1 d-f-justify-space-between' +
                  (activeTab === 1 ? ' active' : '')
                }
                onClick={() => setActiveTab(1)}>
                <div>
                  Текущие возвраты
                  <span>{listRmaPagin?.count > 0 && listRmaPagin?.count}</span>
                </div>
                <a className="current-title-right m-hidden" onClick={() => props.runModalShow(5)}>
                  Как создать заявку
                </a>
              </div>
              <div className={'current-items' + (activeTab === 1 ? ' active' : '')}>
                {listRma?.length > 0 && (
                  <div className="d-flex current-items-yours-block">
                    <div className="current-items-yours d-hidden">
                      Ваши возвраты<span>{listRmaPagin?.count > 0 && listRmaPagin?.count}</span>
                    </div>
                    <a
                      className="current-title-right d-hidden"
                      onClick={() => props.runModalShow(5)}>
                      Как создать заявку
                    </a>
                  </div>
                )}
                {listRma?.length > 0 ? (
                  <Application list={listRma} rmaDetail={rmaDetail} API_URL={props.API_URL} />
                ) : (
                  <AppealProcess />
                )}
                {listRma?.length > 0 && listRmaPagin?.count > 1 && (
                  <Paginate listPagin={listRmaPagin} handlePageClick={handlePageClick} />
                )}
                {listRma?.length > 0 && listRmaPagin?.count / listRmaPagin?.per_page > 1 && (
                  <div className="d-hidden allProduct" onClick={() => rmaList(1, 100)}>
                    Показать все заявки
                  </div>
                )}
              </div>

              <div
                className={
                  'current-title current__tab-title current__tab-title-2' +
                  (activeTab === 2 ? ' active' : '')
                }
                onClick={() => setActiveTab(2)}>
                История возвратов
                <span>{listRmaHistPagin?.count > 0 && listRmaHistPagin?.count}</span>
              </div>
              <div className={'current-items' + (activeTab === 2 ? ' active' : '')}>
                {listRmaHist?.length > 0 && (
                  <div className="d-flex current-items-yours-block">
                    <div className="current-items-yours d-hidden">
                      История возвратов
                      <span>{listRmaHistPagin?.count > 0 && listRmaHistPagin?.count}</span>
                    </div>
                  </div>
                )}
                {listRmaHist?.length > 0 ? (
                  <Application list={listRmaHist} API_URL={props.API_URL} />
                ) : (
                  <div className="current-items-null">У Вас нет истории возвратов</div>
                )}
                {listRmaHist?.length > 0 && listRmaHistPagin?.count > 1 && (
                  <Paginate listPagin={listRmaHistPagin.page} handlePageClick={handlePageClick2} />
                )}
                {listRmaHist?.length > 0 &&
                  listRmaHistPagin?.count / listRmaHistPagin?.per_page > 1 && (
                    <div className="d-hidden allProduct" onClick={() => rmaListHist(1, 100)}>
                      Показать все заявки
                    </div>
                  )}
              </div>
            </div>
          </div>
        ) : (
          <div className="content__page content__page-statement">
            <div className="current d-relat">
              {props.errTextApi !== '' && (
                <div
                  className="form__item form__err"
                  dangerouslySetInnerHTML={{ __html: props.errTextApi }}></div>
              )}
              <div
                className="btn-prev-page"
                onClick={() => {
                  props.setStatement(false);
                  localStorage.removeItem('rmaId');
                  localStorage.removeItem('rmaAddressId');
                  localStorage.removeItem('typeReturnMoney');
                  localStorage.removeItem('statemetId');
                  props.setErrTextApi('');
                }}>
                <span className="ico ico-prev"></span>
                Назад
              </div>
              <div className="form__title-3">Заявление</div>
              {listRmaDetail?.[0]?.typeId === 'change_item' && (
                <p>на замену товара ненадлежащего качества</p>
              )}
              {listRmaDetail?.[0]?.typeId === 'return_money' && (
                <p>на возврат денежных средств за товар ненадлежащего качества</p>
              )}
              {listRmaDetail?.[0]?.typeId === 'return_item' && (
                <p>на возврат товара ненадлежащего качества</p>
              )}

              <div className={'current-items' + (activeTab === 1 ? ' active' : '')}>
                {listRmaDetail?.length > 0 && (
                  <Application list={listRmaDetail} API_URL={props.API_URL} />
                )}
              </div>
              {localStorage.setItem('rmaTypeReturnMoney', listRmaDetail?.[0]?.typeId)}
              {listRmaDetail?.[0]?.typeId === 'change_item' && (
                <p>
                  В связи с обнаружением недостатков в товаре (продукт не работает) прошу в
                  соответствии с п. 1 ст. 18 Закона РФ от 07.02.1992 № 2300-1 «О защите прав
                  потребителей» заменить неисправный товар на товар той же марки (модели, артикула).
                  <br />
                  <br />
                  <br />
                  <hr className="d-hidden" />
                  <br />
                  <br />
                  Замену прошу выслать по указанному ниже адресу.
                </p>
              )}
              {listRmaDetail?.[0]?.typeId === 'return_item' && (
                <p>
                  В связи с обнаружением недостатков в товаре (продукт не работает) в соответствии с
                  п. 1 ст. 18 Закона РФ от 07.02.1992 № 2300-1 «О защите прав потребителей» я
                  отказываюсь от исполнения заключенного договора купли-продажи товара.
                  <br />
                  <br />
                  <br />
                  <hr className="d-hidden" />
                  <br />
                  <br />
                </p>
              )}
              {listRmaDetail?.[0]?.typeId === 'return_money' && (
                <div>
                  <p>
                    В связи с обнаружением недостатков в товаре (продукт не работает) в соответствии
                    с п. 1 ст. 18 Закона РФ от 07.02.1992 № 2300-1 «О защите прав потребителей» я
                    отказываюсь от исполнения заключенного договора купли-продажи товара.
                    <br />
                    <br />
                    <br />
                    <hr className="d-hidden" />
                    <br />
                    <br />
                    Прошу вернуть денежные средства следующим способом:
                  </p>

                  <div className="current-title">Способ возврата средств</div>
                  <div className="changeCash">
                    <p
                      className={!returnCash && 'active'}
                      onClick={() => {
                        setReturnCash(false);
                        localStorage.setItem('typeReturnMoney', 'cash');
                      }}>
                      Наличные почтовым переводом
                    </p>
                    <p
                      className={returnCash && 'active'}
                      onClick={() => {
                        localStorage.setItem('typeReturnMoney', 'transfer');
                        props.setRoomesS([...props.formInput2['changeCash']]);
                        setReturnCash(true);
                      }}>
                      Перечисление на банковский счет
                    </p>
                  </div>
                  {returnCash && (
                    <div>
                      <div className="current-title">Подтвердите данные</div>
                      <br className="m-hidden" />
                      <p>Пожалуйста заполниет ваши данные и подтвердите их</p>
                      <InputS
                        rooms={props.roomesS}
                        setRooms={props.setRoomesS}
                        setValidation={props.setValidationS}
                        togglePass={props.togglePassS}
                        handleroom={props.handleroomS}
                      />
                      <br />
                      <br />
                      <br className="m-hidden" />
                      <br className="m-hidden" />
                    </div>
                  )}
                  <p>Замену прошу выслать по указанному ниже адресу.</p>
                  <br />
                </div>
              )}
              <div className="current-title">Способ доставки</div>
              <br />
              <p>Пожалуйста, выберите адрес доставки товара</p>
              <div className="current-title">
                Адреса
                {props.addressList?.length > 0 && <span>{props.addressList?.length} из 5</span>}
              </div>
              <div className="current-items active">
                {props.addressList?.length > 0 ? (
                  props.addressList?.map((listItem, index) => {
                    activeAddress === listItem.id &&
                      localStorage.setItem('rmaAddressId', listItem.id);
                    return (
                      <div
                        className={
                          'current_item current_item-address' +
                          (activeAddress === listItem.id ? ' active' : '')
                        }
                        key={'address_' + index}
                        onClick={() => {
                          setActiveAddress(listItem.id);
                          localStorage.setItem('rmaAddressId', listItem.id);
                        }}>
                        <div className="current_item-address-left">
                          {listItem.list?.map((listPersonal, index) => {
                            return (
                              <div
                                className="current_item-address-personal"
                                key={'current_item-personal-' + index}>
                                <span>{listPersonal.name}</span>
                                {listPersonal.value}
                              </div>
                            );
                          })}
                        </div>
                        <div className="current_item-address-right">
                          <div className="current_item-address-title">
                            {listItem.default && (
                              <div className="current_item-address-main">Основной адрес</div>
                            )}
                            <span>{listItem.title}</span>
                          </div>
                          <div
                            className="current_item-address-edit"
                            onClick={() => (
                              localStorage.setItem('editAddressId', listItem.id), form()
                            )}>
                            Редактировать <span className="ico ico-edit"></span>
                          </div>
                          <div className="current_item-address-remove">
                            <span
                              className="ico ico-remove"
                              onClick={() => (
                                localStorage.setItem('removeAddressId', listItem.id),
                                props.runModalShow(1, 'newAddress')
                              )}></span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="current-items-null">У Вас пока нет сохраненных адресов:(</div>
                )}
                <span className="d-flex current-items-bottom-btn">
                  <div
                    className="btn btn-blue2 btn-inline"
                    onClick={() => (form(), localStorage.removeItem('removeAddressId'))}>
                    Добавить адрес
                  </div>
                </span>
              </div>
              <InputS
                rooms={props.roomesH}
                setRooms={props.setRoomesH}
                setValidation={props.setValidationH}
                togglePass={props.togglePassS}
                handleroom={props.handleroomH}
              />
              <span className="d-flex text-center current-items-bottom-btn">
                <div
                  onClick={() => props.submit('rmaUpdate')}
                  className={'btn btn-inline ' + (!props.subActive ? ' btn-grey' : ' btn-blue2')}>
                  Подписать и отправить заявление
                </div>
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="modals__list">
        <ModalAggre
          modalShow={props.modalShow}
          modalClose={props.modalClose}
          modalData={props.modals[6]}
          setErrTextApi={props.setErrTextApi}
          key={props.modals[6].id}
          errTextApi={props.errTextApi}
        />
        <Modal
          class={'middle ' + props.modals[5].type}
          modalShow={props.modalShow}
          modalClose={props.modalClose}
          modalData={props.modals[5]}
          key={props.modals[5].id}>
          <AppealProcess />
          <div className="d-flex text-center">
            <div className="btn btn-blue2 btn-inline  " onClick={() => props.modalClose()}>
              Закрыть
            </div>
          </div>
        </Modal>

        {/* address */}
        <Modal
          class={'max'}
          modalShow={props.modalShow}
          modalClose={props.modalClose}
          modalData={props.modals[0]}
          key={props.modals[0].id}>
          {props.errTextApi !== '' && (
            <div
              className="form__item form__err"
              dangerouslySetInnerHTML={{ __html: props.errTextApi }}></div>
          )}
          <div className="modal__box-header d-flex align-items-end justify-content-between">
            <div className="form-title ">
              {localStorage.getItem('editAddressId')
                ? 'Редактирование адреса'
                : 'Добавить Новый Адрес'}
            </div>
          </div>
          {props.blockN.length > 0 && (
            <div className="d-flex form__item-modal-form">
              <Input
                blockN={props.blockN}
                rooms={props.roomesN}
                setRooms={props.setRoomesN}
                setValidation={props.setValidation}
                togglePass={props.togglePass}
                handleroom={props.handleroom}
              />
            </div>
          )}

          <div className="d-flex modal-box-btn">
            <button
              onClick={() => {
                props.modalClose();
                localStorage.getItem('editAddressId') && localStorage.removeItem('editAddressId');
              }}
              className="btn btn-grey2 btn-inline">
              Отмена
            </button>
            <button
              onClick={() => {
                localStorage.getItem('editAddressId')
                  ? props.submit('addressUpdate')
                  : props.submit('addressAdd');
              }}
              className={'btn btn-inline ' + (!props.subActive ? ' btn-grey' : ' btn-blue2')}>
              Сохранить адрес
            </button>
          </div>
        </Modal>
        <Modal
          class={'min'}
          modalShow={props.modalShow}
          modalClose={props.modalClose}
          modalData={props.modals[1]}
          key={props.modals[1].id}>
          <div className="form__title-min">
            Вы действительно хотите <br />
            удалить адрес
          </div>
          <div className="d-flex modal-box-btn">
            <button
              onClick={() => {
                props.modalClose();
              }}
              className="btn btn-grey2 btn-inline">
              Отмена
            </button>
            <button
              onClick={() => {
                localStorage.getItem('removeAddressId') &&
                  props.submit('removeAddress', localStorage.getItem('removeAddressId'));
              }}
              className="btn btn-blue2 btn-inline">
              Удалить
            </button>
          </div>
        </Modal>
        <Modal
          class={'min'}
          modalShow={props.modalShow}
          modalClose={props.modalClose}
          modalData={props.modals[7]}
          key={props.modals[7].id}>
          <div className="form__title-min">Статус заявления обновлён</div>
          <div className="d-flex modal-box-btn">
            <button
              onClick={() => {
                props.modalClose();
              }}
              className="btn btn-grey2 btn-inline">
              Закрыть
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}
