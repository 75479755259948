import React, { useState, useEffect } from 'react';
import Modal from './Modal';
export default function App(props) {
  return (
    <Modal
      class={'middle aggre'}
      modalShow={props.modalShow}
      modalClose={props.modalClose}
      modalData={props.modalData}
      setErrTextApi={props.setErrTextApi}
      key={props.key}>
      {props.errTextApi !== '' && (
        <div
          className="form__item form__err"
          dangerouslySetInnerHTML={{ __html: props.errTextApi }}></div>
      )}
      <div className="form__title form-title-3">
        Согласие на&nbsp;обработку персональных данных, даваемое пользователем устройства FREESTYLE
        LIBRE для получения гарантийного обслуживания
      </div>
      <div className="form__text">
        <p>
          Предоставляя свои персональные данные с&nbsp;целью получения клиентского сервиса
          и&nbsp;гарантийного обслуживания устройства FREESTYLE LIBRE,
        </p>
        <p>
          я&nbsp;даю согласие Обществу с&nbsp;ограниченной ответственностью &laquo;Эбботт
          Лэбораториз&raquo; (далее&nbsp;&mdash; Общество), адрес местонахождения: 125171, Москва,
          Ленинградское шоссе, д.&nbsp;16&nbsp;А, стр.&nbsp;1, на&nbsp;обработку в&nbsp;целях
          обеспечения сопровождения клиентов и&nbsp;гарантийного обслуживания медицинских изделий
          Заказчика предоставленных мною и&nbsp;полученных Обществом самостоятельно в&nbsp;ходе
          взаимодействия со&nbsp;мною персональных данных (мои фамилия, имя, отчество, дата
          и&nbsp;заводской номер приобретенного мною устройства FREESTYLE LIBRE, адрес доставки,
          адрес моей электронной почты, номер контактного телефона, реквизиты банковского счета,
          сведения о&nbsp;моих обращениях в&nbsp;Общество и&nbsp;результатах их&nbsp;рассмотрения),
          включая их&nbsp;передачу в&nbsp;полном объеме для исполнения поручения обработки Обществу
          с&nbsp;ограниченной ответственностью &laquo;Медиа Юнг&raquo;, зарегистрированному
          по&nbsp;адресу: Россия, 129281, г. Москва, Олонецкий проезд, д.&nbsp;4, корп.&nbsp;2,
          эт/пом/ком 5/XII/12), являющемуся оператором сайта Общества, Закрытому акционерному
          обществу &laquo;КРОК инкорпорейтед&raquo;, зарегистрированному по&nbsp;адресу:
          Россия,105082, г.Москва, ул. Большая Почтовая, д.26В, стр.2), предоставляющему услуги
          хостинга сайта Общества, Обществу с&nbsp;ограниченной ответственностью &laquo;Базис
          Авто&raquo;, зарегистрированному по&nbsp;адресу: Россия, 111524, Москва, ул. Электродная,
          д.&nbsp;10, стр.&nbsp;21&nbsp;эт.&nbsp;4&nbsp;пом&nbsp;IV ком&nbsp;2, осуществляющему
          поддержку системы управления взаимоотношениями с&nbsp;клиентами (CRM), Обществу
          с&nbsp;ограниченной ответственностью &laquo;Арвато Рус&raquo;, адрес: Россия, 150029, г.
          Ярославль, ул. Декабристов, д.&nbsp;3, обеспечивающему по&nbsp;поручению Общества передачу
          персональных данных в&nbsp;службы доставки, Обществу с&nbsp;ограниченной ответственностью
          &laquo;МОРИЗО ДИДЖИТАЛ&raquo;, адрес: Россия, 603000, Нижегородская обл., г. Нижний
          Новгород, ул. Алексеевская, д. 6/16, этаж&nbsp;5, помещ.&nbsp;11, предоставляющему
          техническую поддержку сайта Общества и&nbsp;обеспечивающей обработку заявок
          на&nbsp;гарантийное обслуживание, и&nbsp;в&nbsp;объеме, необходимом для приема у&nbsp;меня
          и&nbsp;доставки устройства FREESTYLE LIBRE (фамилия, имя, отчество, адрес доставки, номер
          контактного телефона)&nbsp;&mdash; службам доставки по&nbsp;усмотрению Общества (полный
          список поставщиков услуги доставки размещен на&nbsp;странице сайта Общества в&nbsp;сети
          Интернет по&nbsp;адресу{' '}
          <a href="https://www.freestylediabetes.ru/dostavka">
            https://www.freestylediabetes.ru/dostavka
          </a>
          ). Мне известно, что условия обработки персональных данных Обществом изложены в&nbsp;
          <a href="https://freestylediabetes.ru/upload/site_rules.pdf" target="_BLANK">
            Правилах пользования Сайтом
          </a>{' '}
          и&nbsp;&laquo;
          <a href="https://freestylediabetes.ru/upload/site_rules.pdf" target="_BLANK">
            Политике Общества с&nbsp;ограниченной ответственностью &bdquo;Эбботт Лэбораториз&ldquo;
            в&nbsp;отношении обработки персональных данных
          </a>
          &raquo;.
        </p>
        <p>
          Мне известно, и&nbsp;я&nbsp;понимаю, что обработка персональных данных Обществом, включая
          их&nbsp;предоставление указанным выше лицам, необходима для оказания клиентского сервиса
          и&nbsp;получения услуг гарантийного обслуживания, составляет единый бизнес-процесс,
          и&nbsp;отказ от&nbsp;предоставления данного согласия приведет к&nbsp;невозможности
          предоставления мне услуг гарантийного обслуживания устройства FREESTYLE LIBRE.
        </p>
      </div>
      <div className="d-flex modal-box-btn">
        <button
          onClick={() => {
            !document.getElementById('checkbox').checked &&
              document.getElementById('checkbox').click();
            props.modalClose();
          }}
          className="btn btn-blue2 btn-inline">
          Подтвердить
        </button>
      </div>
    </Modal>
  );
}
