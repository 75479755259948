import React, { useState, useEffect } from 'react';
import Input from './Input';
export default function App(props) {
  useEffect(() => {
    props.setRoomes([...props.formInput2[props.nameForm]]);
    document.body.classList.add('home');
  }, []);

  return (
    <div className="content__max">
      <div className="content__page">
        {props.errTextApi !== '' && (
          <div
            className="form__item form__err"
            dangerouslySetInnerHTML={{ __html: props.errTextApi }}></div>
        )}
        <div className="form text-center d-flex d-aling-center d-f-column">
          <div className="form-title ">Смена пароля</div>
          <div className="form__text">
            <p className="text-center">
              Вам нужно создать новый пароль для доступа <br />в личный кабинет
            </p>
            <p></p>
          </div>
          <Input
            rooms={props.roomes}
            setRooms={props.setRoomes}
            setValidation={props.setValidation}
            togglePass={props.togglePass}
            handleroom={props.handleroom}
          />
          <button
            onClick={() => {
              props.submit(props.nameForm);
            }}
            className={'btn ' + (!props.subActive ? ' btn-grey' : ' btn-blue2')}>
            Сохранить пароль
          </button>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
