import React, { useEffect, useState } from 'react';
import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import ReCAPTCHA from 'react-google-recaptcha';
import InputMask from 'react-input-mask';

export default function RoomListView({ ...props }) {
  const [focus, setFocus] = useState(null);
  const [errM, setErrM] = useState([]);
  const [error2, setError2] = useState([]);
  const [topicDropdown, setTopicDropdown] = useState(null);
  const [obj, setObj] = useState([{ err: false }]);
  const [select, setSelect] = useState(false);
  const [value, setValue] = useState();
  const renderError = (errText, err) => {
    if (err) {
      return <div className="error__message">{errText}</div>;
    }
  };
  const btnTopicDropdown = (id) => {
    id === topicDropdown ? setTopicDropdown(null) : setTopicDropdown(id);
  };
  const crashAddress = (e) => {};
  const returnBlockInput = (id, i) => {
    return (
      <div className="d-flex text-center d-f-column" key={'roomData.nameForm' + '_' + i}>
        <div
          className={
            'form__item ' +
            (focus === id ? 'form-focused' : '') +
            (props?.rooms?.[id]?.fields?.[0].name === 'recaptcha' ? 'recaptcha' : '') +
            (props?.rooms?.[id]?.err ? ' has-error ' : '') +
            (props?.rooms?.[id]?.fields[0]?.type === 'checkbox' &&
            props?.rooms?.[id]?.fields.length > 1
              ? ' form__item-box '
              : '')
          }>
          {props.rooms?.[id]?.title && props.rooms?.[id]?.fields[0].type !== 'checkbox_simple' && (
            <label
              className={
                'form__label' +
                (props.rooms?.[id]?.fields[0].type === 'static_text' ? ' static_text' : '')
              }
              key={'roomData.label' + '_' + i}>
              {props.rooms?.[id]?.title}
              {props.rooms?.[id]?.fields[0].param.required && '*'}
            </label>
          )}
          {props.rooms?.[id]?.fields?.map((inpData, inpI) => {
            // return inpData?.name;
            switch (inpData.type) {
              case 'select' && 'dropdown':
                // setSelect(true);
                return (
                  inpI == '0' && (
                    <div
                      className={
                        'form__field form__select ' +
                        (props.rooms?.[id]?.err ? 'has-error ' : '') +
                        (topicDropdown === id ? 'active ' : '') +
                        select
                      }
                      key={'roomData.inputForm' + '_' + inpI}>
                      <div className="select__custom">
                        <div
                          onClick={() => btnTopicDropdown(id)}
                          className={
                            'select__custom-input d-flex align-items-center ' +
                            (topicDropdown === id ? 'active' : '')
                          }>
                          <span>
                            {props.rooms?.[id]?.value != ''
                              ? props.rooms?.[id]?.value
                              : props.rooms?.[id]?.placeholder}
                          </span>
                          <div
                            className={
                              'select__custom-arrow d-flex align-items-center justify-content-center ' +
                              (topicDropdown === id ? 'active' : '')
                            }>
                            <i
                              className={
                                'select__arrow ' + (topicDropdown === id ? 'active' : '')
                              }></i>
                          </div>
                        </div>
                        <div
                          className={
                            'select__custom-dropdown ' + (topicDropdown === id ? 'active' : '')
                          }>
                          <div className="select__custom-dropdown-list">
                            {props.rooms?.[id].fields?.map((inpDataS, inpIS) => (
                              <div
                                onClick={() => {
                                  setTopicDropdown(null);
                                  props.handleroom(inpDataS.title, inpDataS.type, i, inpIS, id);
                                }}
                                className={'select__custom-dropdown-item '}
                                key={'roomData.dropdown' + '_' + inpIS}>
                                {inpDataS.title}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {renderError(props.rooms?.[id]?.errorText, props.rooms?.[id]?.err)}
                    </div>
                  )
                );
              case 'checkbox_simple':
                return (
                  <div
                    className={
                      'checkbox__item checkbox_simple d-flex ' +
                      (props.rooms?.[id]?.err ? 'has-error' : '')
                    }
                    key={'roomData.checkbox' + '_' + inpI}>
                    <label>
                      <input
                        type="checkbox"
                        name={inpData.name}
                        onFocus={() => {
                          setFocus(inpI);
                          setTopicDropdown(null);
                          props.setValidation(false, i, '', id);
                        }}
                        onChange={(e) => {
                          props.handleroom(e.target.checked, inpData.type, i, inpI, id);
                          // props.setValidation(false, inpI);
                          props.setValidation(false, i, '', id);
                        }}
                        checked={inpData.value}
                      />
                      <div className="checkbox__decor"></div>
                      <div
                        className="checkbox__title"
                        dangerouslySetInnerHTML={{ __html: inpData.title }}></div>
                    </label>
                    {renderError(props.rooms?.[id]?.errorText, props.rooms?.[id]?.err)}
                  </div>
                );
              case 'checkbox':
                return (
                  <div
                    className={
                      'checkbox__item d-flex ' + (props.rooms?.[id]?.err ? 'has-error' : '')
                    }
                    key={'roomData.checkbox' + '_' + inpI}>
                    <label>
                      <input
                        type={inpData.type}
                        name={inpData.value}
                        onFocus={() => {
                          setFocus(inpI);
                          setTopicDropdown(null);
                          props.setValidation(false, i, '', id);
                        }}
                        onChange={(e) => {
                          console.log('qweqwe');
                          props.handleroom(e.target.checked, inpData.type, i, inpI, id);
                          // props.setValidation(false, inpI);
                        }}
                        checked={inpData.selected}
                      />
                      <div className="checkbox__decor"></div>
                      <div
                        className="checkbox__title"
                        dangerouslySetInnerHTML={{ __html: inpData.title }}></div>
                    </label>
                    {renderError(props.rooms?.[id]?.errorText, props.rooms?.[id]?.err)}
                  </div>
                );
              case 'address_dadata':
                return (
                  <AddressSuggestions
                    token="ecc4c52c129ea0f46dc1ac832938f01dd3a1cea2"
                    value={inpData.value}
                    selectOnBlur={true}
                    defaultQuery={inpData.value}
                    highlightClassName={'dadata__active'}
                    onChange={(e) => props.handleroom(e, inpData.type, i, inpI, id)}
                  />
                );
              case 'phone':
                return (
                  <div
                    className={[
                      'form__field ',
                      inpData.name && inpData.name,
                      props.rooms?.[id]?.err ? 'has-error' : '',
                    ].join(' ')}
                    key={'roomData.inputForm' + '_' + inpI}>
                    <InputMask
                      type="phone"
                      mask="+79999999999"
                      alwaysShowMask={false}
                      value={inpData.value}
                      // onBlur={() => {
                      //   let data = this.state.data;
                      //   data.reg.phoneFocus = false;
                      //   this.setState({ data: data });
                      // }}
                      onFocus={() => {
                        setFocus(i);
                        setTopicDropdown(null);
                        props.setValidation(false, i, '', id);
                      }}
                      onChange={(e) => props.handleroom(e, inpData.type, i, inpI, id)}
                    />
                    {!inpData.param.readonly &&
                      renderError(props.rooms?.[id]?.errorText, props.rooms?.[id]?.err)}
                  </div>
                );
              default:
                return (
                  <div
                    className={[
                      'form__field ',
                      inpData.name && inpData.name,
                      props.rooms?.[id]?.err ? 'has-error' : '',
                    ].join(' ')}
                    key={'roomData.inputForm' + '_' + inpI}>
                    <input
                      type={inpData.type}
                      value={inpData.value}
                      name={inpData.name}
                      readonly={inpData.param.readonly ? 'readonly' : false}
                      onFocus={() => {
                        setFocus(i);
                        setTopicDropdown(null);
                        props.setValidation(false, i, '', id);
                      }}
                      onChange={(e) => props.handleroom(e, inpData.type, i, inpI, id)}
                      // onChange={(e) => console.log(e.target)}
                    />
                    {(inpData.name === 'password' || inpData.name === 'password_confirm') && (
                      <span
                        className={'togglePass ' + (inpData.type === 'password' ? 'active' : '')}
                        onClick={() => {
                          props.togglePass(i, inpI);
                        }}></span>
                    )}
                    {!inpData.param.readonly &&
                      renderError(props.rooms?.[id]?.errorText, props.rooms?.[id]?.err)}
                  </div>
                );
            }
          })}
        </div>
      </div>
    );
  };
  return (
    <div>
      {props?.blockN &&
        props?.blockN?.length > 0 &&
        props?.blockN.map((blocksData, i) => {
          return (
            <div key={'blocksData-' + i} className="form__item-container">
              {blocksData?.rows &&
                blocksData?.rows?.length > 0 &&
                blocksData?.rows.map((blockData, i) => {
                  if (blockData.key !== null) {
                    return returnBlockInput(blockData.key, i);
                  } else {
                    return (
                      <div
                        className={
                          'form__item-container form__item-container-' +
                          blockData.block?.rows[0]?.cols.length
                        }>
                        {blockData?.block?.rows[0]?.cols.map((blockData2, i) => {
                          return returnBlockInput(blockData2.key, i);
                        })}
                      </div>
                    );
                  }
                })}
            </div>
          );
        })}
    </div>
  );
}
