import React, { Component } from 'react';
require('es6-promise').polyfill();
require('isomorphic-fetch');
export default class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}
  render() {
    return (
      <div className="appeal-process">
        <div className="appeal-process-title">
          Процесс гарантийного обращения <br />
          состоит из&nbsp;4&nbsp;простых шагов
        </div>
        <div className="appeal-process-items">
          <div className="appeal-process-item">
            <div className="appeal-process-item-ico appeal-process-item-ico-1"></div>
            <div className="appeal-process-item-title">
              Позвоните по телефону{' '}
              <b>
                <span>8&nbsp;800&nbsp;100&nbsp;88&nbsp;07</span>
              </b>
            </div>
            <div className="appeal-process-item-text">Объясните оператору суть претензии</div>
          </div>
          <div className="appeal-process-item">
            <div className="appeal-process-item-ico appeal-process-item-ico-2"></div>
            <div className="appeal-process-item-title">
              Мы проведем оценку предоставленной информации
            </div>
            <div className="appeal-process-item-text">
              В&nbsp;случае, если случай будет признан гарантийным, мы&nbsp;оповестим Вас
              по&nbsp;электронной почте
            </div>
          </div>
          <div className="appeal-process-item">
            <div className="appeal-process-item-ico appeal-process-item-ico-3"></div>
            <div className="appeal-process-item-title">Заполните претензионное заявление</div>
            <div className="appeal-process-item-text">
              Заполните и&nbsp;подтвердите претензионное заявление в&nbsp;личном кабинете
              на&nbsp;сайте в&nbsp;специальном разделе
            </div>
          </div>
          <div className="appeal-process-item">
            <div className="appeal-process-item-ico appeal-process-item-ico-4"></div>
            <div className="appeal-process-item-title">
              Мы&nbsp;осуществим замену товара или возврат средств
            </div>
            <div className="appeal-process-item-text">
              После проверки информации, мы&nbsp;осуществим замену товара или возврат средств.{' '}
            </div>
            <div className="appeal-process-item-text">
              Вы&nbsp;сможете отслеживать статус обработки претензии в&nbsp;личном кабинете
            </div>
          </div>
        </div>
      </div>
    );
  }
}
